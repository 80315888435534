import { memo } from 'react'

const NameInput = ({ 
    label , type = 'text' , placeholder = '' , name = '' , setData , data , ...props
}) => {

    const handleChange = (e) => {
        const { name , value } = e.target;
        setData(prev => ({...prev , [name] : value }))
    }

    return (
        <div className='flex flex-col gap-1.5 flex-1 w-full'>
            {
                label && 
                <label className='font-semibold text-gray-600 '>
                    {label}
                </label>
            }
            <input 
            type={type}
            placeholder={placeholder}
            className='input w-full'
            name={name}
            value={data[name]}
            onChange={handleChange}
            {...props}
            />
        </div>
    )
}

export default memo(NameInput)