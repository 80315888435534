import ImagePopup from 'components/global/ImagePopup';
import Input from 'components/global/Input';
import RequestStatus from 'components/global/RequestStatus';
import Textarea from 'components/global/Textarea';
import { baseURL } from 'config/api';
import React, { useState } from 'react';
import Accordion from 'react-accordion-comp';

const KycDetails = ({ kycData }) => {
    const [showKyc , setShowKyc] = useState(false);
    const [showImg , setShowImg] = useState(false);
    const [currentImg , setCurrentImg] = useState(false);

    const handleImgClick = (imgUrl) => {
        setCurrentImg(imgUrl);
        setShowImg(true)
    }

    return (
        <div className='shadow-bg p-4'>
            <div 
            className='flex items-center justify-between border-b pb-4 cursor-pointer'
            onClick={() => setShowKyc(prev => !prev)}
            >
                <div className="heading-sm flex items-center gap-4">
                    <span>KYC Details</span> 
                    <RequestStatus status={kycData?.status} />
                </div>
                <div className="text-2xl">
                    {
                        showKyc
                        ?
                            <i className="uil uil-angle-up"></i>
                        :
                            <i className="uil uil-angle-down"></i>
                    }
                </div>
            </div>
            <Accordion isOpen={showKyc}>
                <div>
                    <div 
                    className='flex flex-col gap-4 mt-6'
                    >
                        <Input 
                        type='number'
                        label='aadhaar Card Number'
                        value={kycData?.aadhaarCardNumber}
                        readOnly
                        disabled
                        />
                        <Input 
                        label='Driving License '
                        value={kycData?.licenseCardNumber}
                        readOnly
                        disabled
                        />
                        {
                            kycData?.reason?.length > 0 && 
                            <Textarea 
                            label={`${kycData?.status} Reason`}
                            value={kycData?.reason}
                            readOnly
                            disabled
                            />
                        }
                        <div className='flex gap-6 md:flex-row flex-col mt-4'>
                            <div className="input-group flex-1">
                                <label>Aadhaar Card Front Image</label>
                                <div className='overflow-hidden w-full mt-2 border rounded-md'>
                                    <img 
                                    src={baseURL + '/aadhaarCards/' + kycData?.aadhaarCardFrontImage} 
                                    alt="aadhaar card" 
                                    className='rounded-md w-full lg:h-[400px] md:h-[300px] h-[200px] object-cover hover:scale-105 duration-200 ease-in-out cursor-pointer'
                                    onClick={() => {
                                        handleImgClick(baseURL + '/aadhaarCards/' + kycData?.aadhaarCardFrontImage)
                                    }}
                                    />
                                </div>
                            </div>
                            <div className="input-group flex-1">
                                <label>Aadhaar Card Back Image</label>
                                <div className='overflow-hidden w-full mt-2 border rounded-md'>
                                    <img 
                                    src={baseURL + '/aadhaarCards/' + kycData?.aadhaarCardBackImage} 
                                    alt="aadhaar card" 
                                    className='rounded-md w-full lg:h-[400px] md:h-[300px] h-[200px] object-cover hover:scale-105 duration-200 ease-in-out cursor-pointer'
                                    onClick={() => handleImgClick(baseURL + '/aadhaarCards/' + kycData?.aadhaarCardBackImage)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-6 md:flex-row flex-col mt-4'>
                            <div className="input-group flex-1">
                                <label>License Card Front Image</label>
                                <div className='overflow-hidden w-full mt-2 border rounded-md'>
                                    <img 
                                    src={baseURL + '/licenseCards/' + kycData.licenseCardFrontImage} 
                                    alt="License card" 
                                    className='rounded-md w-full lg:h-[400px] md:h-[300px] h-[200px] object-cover hover:scale-105 duration-200 ease-in-out cursor-pointer'
                                    onClick={() => handleImgClick(baseURL + '/licenseCards/' + kycData.licenseCardFrontImage)}
                                    />
                                </div>
                            </div>
                            <div className="input-group flex-1">
                                <label>License Card Front Image</label>
                                <div className='overflow-hidden w-full mt-2 border rounded-md'>
                                    <img 
                                    src={baseURL + '/licenseCards/' + kycData.licenseCardBackImage} 
                                    alt="License card" 
                                    className='rounded-md w-full lg:h-[400px] md:h-[300px] h-[200px] object-cover hover:scale-105 duration-200 ease-in-out cursor-pointer'
                                    onClick={() => handleImgClick(baseURL + '/licenseCards/' + kycData.licenseCardBackImage)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-6 md:flex-row flex-col mt-4'>
                            <div className="input-group flex-1">
                                <label>
                                    {kycData?.additionalDocumentType}
                                </label>
                                <div className='overflow-hidden w-full mt-2 border rounded-md'>
                                    <img 
                                    src={baseURL + '/additionalDocuments/' + kycData?.additionalDocumentImage} 
                                    alt="License card" 
                                    className='rounded-md w-full lg:h-[400px] md:h-[300px] h-[200px] object-cover hover:scale-105 duration-200 ease-in-out cursor-pointer'
                                    onClick={() => handleImgClick(baseURL + '/additionalDocuments/' + kycData?.additionalDocumentImage)}
                                    />
                                </div>
                            </div>
                            <div className="input-group flex-1">
                                <label>
                                    Selfie
                                </label>
                                <div className='overflow-hidden w-full mt-2 border rounded-md'>
                                    <img 
                                    src={baseURL + '/selfies/' + kycData?.selfie} 
                                    alt="License card" 
                                    className='rounded-md w-full lg:h-[400px] md:h-[300px] h-[200px] object-cover hover:scale-105 duration-200 ease-in-out cursor-pointer'
                                    onClick={() => handleImgClick(baseURL + '/selfies/' + kycData?.selfie)}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    
                    </div>
                </div>
            </Accordion>

            { showImg && <ImagePopup setShowPopup={setShowImg} img={currentImg} />}
        </div>
    )
}

export default KycDetails