import * as Yup from 'yup';

const insuranceValidations = Yup.object().shape({
    name: Yup.string().required('Insurance plan name is required.'),
    image: Yup.string().required('Image is required.'),
    price: Yup.number()
        .typeError('Price must be a number.')
        .required('Price is required.'),
    description: Yup.string().required('Description is required.')
});

export default insuranceValidations;
