import SelectVendor from 'components/carsManagement/SelectVendor';
import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import PlacesInput from 'components/global/PlacesInput';
import FormControl from 'components/global/form/FormControl';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { car_createDoc } from 'redux/actions/carActions';
import carValidation from 'validations/carValidations';


const vehicleTypes = ['Sedan', 'SUV', 'Hatchback', 'Convertible', 'Luxury', 'Sports', 'Electric', 'Hybrid', 'Van', 'Truck', 'Minivan'];
const fuelTypes = [ "gasoline", "diesel", "electric" , "CNG" , "petrol" ];

const initValues = {
    name : "" ,
    make : "",
    model: "",
    year: '',
    vehicleType : 'Sedan' ,
    transmissionType: "automatic",
    fuelType: "electric",
    mileage: '',
    licensePlate: "",
    seats: 5,
    doors: 4,
    airConditioning: true,
    audioSystem: "",
    rentPerHour: '',
    pickupInstructions: "" ,
    isActive : true ,
    RC :'' ,
    POC :'' ,
    insurance :'' ,
    images : [] , 
    hasDriver : false ,
    selfDrive : true ,
    driverRentPerDay : 0 ,
}

const AddNewCar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [carData , setCarData] = useState(initValues);
    const [ carLocation , setCarLocation ] = useState({
        address :'' ,
        lat :'' , 
        lng :'' ,
        error : ''
    });
    const [ dropOffLocation , setDropOffLocation ] = useState({
        address :'' ,
        lat :'' , 
        lng :'' ,
        error : ''
    });
    const [vendor , setVendor] = useState(null)
    const { createLoading } = useSelector(state => state.car);


    
    const handleSubmit = (formData) => {
        if(!carLocation?.address) {
            setCarLocation(prev => ({...prev , error : 'Pickup location is required.'}));
            return window.scrollTo(0,0);
        } 
        if(!dropOffLocation?.address) {
            setDropOffLocation(prev => ({...prev , error : 'Drop off location is required.'}));
            return window.scrollTo(0,0);
        }
        const data = {
            ...formData , 
            location : {
                address : carLocation?.address,
                coordinates : [parseFloat(carLocation?.lng) , parseFloat(carLocation?.lat)]
            } ,
            dropOffLocation : {
                address : dropOffLocation?.address,
                coordinates : [parseFloat(dropOffLocation?.lng) , parseFloat(dropOffLocation?.lat)]
            } , 
            user : vendor?._id
        }
        dispatch(car_createDoc(data , navigate))
    }
    
    return (
        <Layout>
            <div className='pb-12'>
                <div className='flex justify-between items-center'>
                    <Heading title='Add New Car' />
                    <BackBtn />
                </div>
                <div className='shadow-bg p-4 mt-8'>
                    <Formik
                    initialValues={carData}
                    validationSchema={carValidation}
                    onSubmit={handleSubmit}
                    >
                        {
                            (formik) => { 
                                return (
                                    <Form
                                    className='flex flex-col gap-4'
                                    >
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='input'
                                            label='Name*'
                                            placeholder='Ex : Enter car name'
                                            name='name'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            type='number'
                                            label='Rent Per Hour*'
                                            placeholder='Ex : 100'
                                            name='rentPerHour'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <div className='w-full flex-1'>
                                                <PlacesInput
                                                label='Pickup Location*'
                                                location={carLocation}
                                                setLocation={setCarLocation}
                                                />
                                            </div>
                                            <div className='w-full flex-1'>
                                                <PlacesInput
                                                label='Drop Off Location*'
                                                location={dropOffLocation}
                                                setLocation={setDropOffLocation}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <SelectVendor 
                                            setVendor={setVendor}
                                            vendor={vendor}
                                            />
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='input'
                                            label='Make*'
                                            placeholder='Ex : Toyota'
                                            name='make'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            label='Model*'
                                            placeholder='Ex : Camry'
                                            name='model'
                                            formik={formik}
                                            />
                                        </div>

                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='select'
                                            label='Vehicle Type*'
                                            name='vehicleType'
                                            options={vehicleTypes.map(item => ({
                                                key : item , value : item 
                                            }))}
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='select'
                                            label='Transmission*'
                                            name='transmissionType'
                                            options={['automatic', 'manual'].map(item => ({
                                                key : item , value : item 
                                            }))}
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='input'
                                            type='number'
                                            label='Year*'
                                            placeholder='Ex : 2010'
                                            name='year'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='select'
                                            label='Fuel Type*'
                                            name='fuelType'
                                            options={fuelTypes.map(item => ({
                                                key : item , value : item 
                                            }))}
                                            formik={formik}
                                            />                                   
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='input'
                                            label='License Plate*'
                                            placeholder='Ex : ABC123'
                                            name='licensePlate'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            label='Mileage*'
                                            placeholder='Car mileage'
                                            name='mileage'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='input'
                                            type='number'
                                            label='Seats*'
                                            placeholder='Ex : 5'
                                            name='seats'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            type='number'
                                            label='Doors*'
                                            placeholder='Ex : 4'
                                            name='doors'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='select'
                                            label='Air Conditioning (optional)'
                                            name='airConditioning'
                                            options={[
                                                { key : 'Yes' , value : true } ,
                                                { key : 'No' , value : false } ,
                                            ]}
                                            formik={formik}
                                            />   
                                            <FormControl
                                            control='input'
                                            label='Audio System (optional)'
                                            placeholder='Ex : CD Player, Bluetooth'
                                            name='audioSystem'
                                            formik={formik}
                                            />   
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col justify-between'>
                                            <FormControl
                                            control='toggle'
                                            label='Active For Rent*'
                                            name='isActive'
                                            formik={formik}
                                            />  
                                            <FormControl
                                            control='toggle'
                                            label='Self Drive'
                                            name='selfDrive'
                                            formik={formik}
                                            />  
                                            <FormControl
                                            control='toggle'
                                            label='With Driver'
                                            name='hasDriver'
                                            formik={formik}
                                            />   
                                        </div>
                                        {
                                            formik.values.hasDriver &&
                                            <div>
                                                <FormControl
                                                control='input'
                                                label='Driver Rent Per Day'
                                                placeholder='Please enter driver per day rent'
                                                name='driverRentPerDay'
                                                formik={formik}
                                                />   
                                            </div>
                                        }
                                        <div>
                                            <FormControl
                                            label='Pickup Instructions (optional)'
                                            control='textarea'
                                            name='pickupInstructions'
                                            placeholder='Pickup instructions for customer'
                                            formik={formik}
                                            />
                                            <div className='text-sm mt-1 text-gray-600 font-semibold text-right'>
                                                <span>
                                                    {formik?.values?.pickupInstructions?.length}/400
                                                </span>
                                            </div>
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='file'
                                            label={'Add RC (Registration Certificate)*'}
                                            name='RC'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='file'
                                            label={'Add PUC*'}
                                            name='POC'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='flex gap-4 sm:flex-row flex-col'>
                                            <FormControl
                                            control='file'
                                            label={'Add Insurance*'}
                                            name='insurance'
                                            formik={formik}
                                            />
                                            
                                        </div>
                                        <div>
                                            <FormControl
                                            control='multi-file'
                                            label={'Add Car Images**'}
                                            name='images'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <button 
                                            className="btn-primary py-2 px-12"
                                            disabled={createLoading || !formik.isValid}
                                            >
                                                {
                                                    createLoading
                                                    ?
                                                        <ClipLoader size={20} color='white' />
                                                    : 
                                                        'Create'
                                                }
                                            </button>
                                        </div>

                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </div>
            </div>
        </Layout>
    )
}

export default AddNewCar;