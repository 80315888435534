import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { slug_delete } from 'redux/actions/slugActions';
import { setDocDetails, setSelectedDoc } from 'redux/reducers/slugReducer';
import confirmBox from 'utils/confirmBox';

const SlugsTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { docs  , deleteLoading , selectedDoc } = useSelector(state => state.slug);

    
    const deleteHandler = async (doc) => {
        dispatch(setSelectedDoc(doc));
        const message ='Are you sure? You want to delete this slug?';
        const onYesClick = () => {
            dispatch(slug_delete(doc?._id));
        };
        confirmBox({ message , onYesClick });
    }

    const editHandler = (doc) => {
        dispatch(setDocDetails(doc));
        navigate(`/slugs/edit/${doc?.slug}`);
    }

    
    return (
        <div className="overflow-x-auto rounded-lg">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Name
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Date
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                {
                    docs?.map((doc , i) => (
                        <tr
                        key={doc?._id} 
                        className="bg-white border-b transition duration-300 ease-in-out"
                        >
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {doc?.slug}
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {moment(doc?.createdAt).format('DD MMM YYYY')}
                        </td>
                        
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                            <div className='flex items-center justify-center gap-4'>  
                                <button 
                                className='edit-round'
                                title='Edit Category'
                                onClick={() => editHandler(doc)}
                                >
                                    <i className="uil uil-pen"></i>
                                </button>
                                {
                                    deleteLoading && selectedDoc?._id === doc?._id 
                                    ? 
                                        <ClipLoader size={20} />
                                    : 
                                    <button 
                                    className='delete-round'
                                    title='Delete Category'
                                    onClick={() => deleteHandler(doc)}
                                    >
                                        <i className="uil uil-trash"></i>
                                    </button>
                                }
                            </div>
                        </td>
                    </tr>
                    ))
                }
                
                </tbody>
            </table>

            
        </div>
    )
}

export default SlugsTable;