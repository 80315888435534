import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout';
import PasswordInput from 'components/global/PasswordInput'
import FormControl from 'components/global/form/FormControl';
import Axios from 'config/api';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import updatePasswordValidations from 'validations/updatePasswordValidtions';

const initState = {
    oldPassword : '' ,
    newPassword : '' ,
    confirmPassword : ''
};

const UpdatePassword = () => {
    const [loading , setLoading] = useState(false);

    const { user } = useSelector(state => state.auth);

    const submitHandler = async (formData , submitProps) => {
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.put('/user/update-password' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            submitProps.resetForm();
            toast.success(message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log('Update password error' , err);
            toastError(err);
        }
    }

    return (
        <div>
            <div>
                <Heading title='Update Password' showIcon={false} />
            </div>
            <div className='shadow-bg bg-pure p-4 mt-4'>
                <Formik
                initialValues={initState}
                validationSchema={updatePasswordValidations}
                onSubmit={submitHandler}
                >
                    {   
                        (formik) => {
                            return (
                                <Form
                                className='flex flex-col gap-4'
                                >
                                    <div className='flex sm:flex-row flex-col gap-4'>
                                        <FormControl
                                        control='password' 
                                        label='Current Password'
                                        placeholder='Enter Your Current Password'
                                        name='oldPassword'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='password' 
                                        label='New Password'
                                        placeholder='Ex : **************'
                                        name='newPassword'
                                        formik={formik}
                                        />
                                    </div>
                                    <FormControl
                                    control='password' 
                                    label='Confirm New Password'
                                    placeholder='Ex : *************'
                                    name='confirmPassword'
                                    formik={formik}
                                    />
                                        
                                    <div className='mt-4 flex items-end justify-end'>
                                        <button 
                                        className="btn-primary py-2 px-12"
                                        disabled={loading || !formik.isValid}
                                        >
                                            {
                                                loading
                                                ?
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Save'
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )    
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}

export default UpdatePassword