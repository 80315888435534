import BackBtn from 'components/global/BackBtn'
import FormControl from 'components/global/form/FormControl'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import { baseURL } from 'config/api'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners'
import { insurance_edit, insurance_getDetails } from 'redux/actions/insuranceActions'
import insuranceValidations from 'validations/insuranceValidations'



const EditInsurance = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { updateLoading , docDetails } = useSelector(state => state.insurance);

    const initValues = {
        name :  docDetails?.name || '' ,
        price : docDetails?.price || '' , 
        image : docDetails?.image ? baseURL + '/insurance/' + docDetails?.image : '' ,
        description : docDetails?.description || ''
    }
   
    useEffect(() => {
        if(!docDetails) {
            dispatch(insurance_getDetails(id))
        }
    } , [docDetails])

    const submitHandler = formData => {
        const body = {...formData}
        if(body?.image === initValues?.image) {
            delete body?.image;
        }
        dispatch(insurance_edit(id , body , navigate));
    }


    return (
        <Layout>
            <div>
                <div className='mt-4 flex items-center justify-between'>
                    <div>
                        <Heading title='Add Insurance' showIcon={false} />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                <div className='mt-6'>
                    <Formik
                        initialValues={initValues}
                        validationSchema={insuranceValidations}
                        onSubmit={submitHandler}
                        enableReinitialize
                    >
                        {
                            formik => {
                                return (
                                    <Form
                                        className='flex flex-col gap-4 shadow-bg p-4'
                                    >
                                        <FormControl
                                            control='input'
                                            label="Name"
                                            placeholder="Enter insurance name"
                                            name='name'
                                            formik={formik}
                                        />
                                        <FormControl
                                            control='input'
                                            type='number'
                                            label="Price"
                                            placeholder="Enter insurance price"
                                            name='price'
                                            formik={formik}
                                        />
                                        
                                        <FormControl
                                            control='textarea'
                                            label="Description"
                                            placeholder='Write here...'
                                            name='description'
                                            formik={formik}
                                        />
                                        <FormControl
                                            control='file'
                                            label="Icon"
                                            name='image'
                                            formik={formik}
                                        />
                                        <div className='mt-4'>
                                            <button
                                                type='submit'
                                                className='btn-primary py-2 px-12'
                                                disabled={updateLoading || !formik.isValid}
                                            >
                                                {
                                                    updateLoading
                                                    ?
                                                        <ClipLoader size={20} color='white' />
                                                    :
                                                        'Update'
                                                }
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </div>
            </div>
        </Layout>
    )
}

export default EditInsurance;