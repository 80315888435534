import Axios from 'config/api';
import { setUpdateLoading , updateDoc } from 'redux/reducers/kycReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const url = '/kyc';

export const kyc_updateDoc = (docId , updateData , setShowPopup ) => async (dispatch , getState) => {
    dispatch(setUpdateLoading(true))
    try {
        const { data : { data : { doc , message } } } = await Axios.put(`${url}/${docId}` , updateData , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        });
        toast.success(message)
        dispatch(updateDoc(doc));
        if(setShowPopup) {
            setShowPopup(false)
        }
        dispatch(setUpdateLoading(false));
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}