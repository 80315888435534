import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import PopupLayout from 'components/global/PopupLayout';
import Search from 'components/global/Search';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDocs, setDocsCount, setKeyword } from 'redux/reducers/userReducer';
import fetcher from 'utils/fetcher';


const SelectVendorPopup = ({ setShow , setVendor , vendor }) => {
    const dispatch = useDispatch();

    const { 
        docs : users , docsCount , keyword 
    } = useSelector((state => state.user));
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-users' , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user/total-searched?&keyword=${keyword}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount } } } = data;
            if(vendor) {
                let _docs = docs?.filter(d => d?._id !== vendor?._id);
                dispatch(setDocs([vendor , ..._docs]))
            }else {
                dispatch(setDocs(docs));
            }
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    useEffect(() => {
        return () => dispatch(setKeyword(''))
    } , [])


    const searchFetcher = async (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <PopupLayout
        setShowPopup={setShow}
        width={'lg:w-[50%] md:w-[65%] sm:w-[80%] w-full'}
        >
            <center>
                <h4 className="heading-sm">
                    Search to Select Vendor
                </h4>
            </center>
            <div className='mt-4'>
                <Search fetcher={searchFetcher} />
            </div>
            <div>
                {
                    isLoading
                    ? 
                        <Loader />
                    : 
                    users?.length > 0 
                    ? 
                        <div className='mt-4 grid md:grid-cols-2 grid-cols-1 gap-4 max-h-[400px] overflow-auto py-4'>
                            {
                                users?.map(item => (
                                    <div
                                    key={item?._id}
                                    className={`rounded-md bg-gray-100 p-4 shadow-md hover:bg-gray-200 flex items-center gap-2
                                    ${vendor?._id === item?._id ? 'border-2 border-primary' : ''}
                                    `}
                                    onClick={() => {
                                        setVendor(item);
                                    }}
                                    >
                                        <div className=''>
                                            <i className="uil uil-user-square text-black text-4xl"></i>
                                        </div>
                                        <div>
                                            <p className='text-sm'>{item?.fullName}</p>
                                            <p className='text-primary'>
                                                {item?.phone}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    : 
                        <ItemNotFound message='No venodr found.' />
                }
            </div>
        </PopupLayout>
    )
}


const SelectVendor = ({ vendor , setVendor }) => {
    const [show , setShow] = useState(false);

    return (
        <div className='flex-1'>
            <div className="form-row">
                <label>Select Vendor</label>
                <input 
                type="text" 
                placeholder='tap to select vendor '
                value={vendor?.fullName}
                required
                onClick={() => setShow(true)}
                readOnly
                />
            </div>
            { 
                show && 
                <SelectVendorPopup 
                setShow={setShow} 
                setVendor={setVendor}
                vendor={vendor}
                /> 
            }
        </div>
    )
}

export default SelectVendor;