import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Input from 'components/global/Input'
import Layout from 'components/global/Layout';
import React, { useEffect } from 'react';
import { useState } from 'react';
import SelectBox from 'components/global/SelectBox';
import DatePicker_ from 'components/global/Datepicker';
import NameInput from 'components/global/NameInput';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { coupon_createDoc, coupon_edit, coupon_getDetails } from 'redux/actions/couponActions';
import { ClipLoader } from 'react-spinners';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import { Form, Formik } from 'formik';
import couponValidation from 'validations/CouponValidations';
import FormControl from 'components/global/form/FormControl';

const initValues = {
    code : '' , 
    couponType : '' , 
    discountType : '' , 
    discount : '' , 
    minBookingAmount : '' , 
    startDate : '' , 
    endDate : '' , 
    isActive : true , 
    maxUseCount : 10
}

const EditCoupon = () => {
    const { id } = useParams();
    const [couponData , setCouponData] = useState(initValues);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { updateLoading , loading , docDetails : coupon } = useSelector(state => state.coupon);

    useEffect(() => {
        dispatch(coupon_getDetails(id))
    }, [id , dispatch]);

    useEffect(() => {
        if(coupon) {
            setCouponData({
                ...coupon , 
                startDate : new Date(coupon?.startDate) ,
                endDate : new Date(coupon?.endDate)
            });
        }
    }, [coupon])

    const submitHanlder = async formData => {
        dispatch(coupon_edit(id , formData , navigate));
    }
    

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Update Coupon' icon='pen' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    coupon 
                    ? 
                        <Formik
                        initialValues={couponData}
                        validationSchema={couponValidation}
                        onSubmit={submitHanlder}
                        enableReinitialize
                        >
                            {
                                formik => {
                                    return (
                                        <Form
                                        className='shadow-bg p-4 mt-4 flex flex-col gap-4'
                                        >
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='input'
                                                label='Coupon Code'
                                                placeholder='Ex: test110'
                                                name='code'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='select'
                                                options={[
                                                    { key : 'First Booking' , value : 'firstBooking' } , 
                                                    { key : 'Default' , value : 'default' } , 
                                                ]}
                                                label='Coupon Type'
                                                name='couponType'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='select'
                                                options={[
                                                    { key : 'Percentage' , value : 'percentage' } , 
                                                    { key : 'Amount' , value : 'amount' } , 
                                                ]}
                                                label='Discount Type'
                                                name='discountType'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Discount'
                                                name='discount'
                                                placeholder='Enter discount value'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='input'
                                                label='Min Booking Amount'
                                                placeholder='Minimum order price'
                                                name='minBookingAmount'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='select'
                                                options={[
                                                    { key : 'Active' , value : true } , 
                                                    { key : 'inActive' , value : false } , 
                                                ]}
                                                label='Status'
                                                name='isActive'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='date'
                                                label='Start Date'
                                                placeholderText='Select start date'
                                                name='startDate'
                                                formik={formik}
                                                minDate={new Date()}
                                                />
                                                <FormControl
                                                control='date'
                                                label='End Date'
                                                name='endDate'
                                                placeholderText='Select coupon end date'
                                                data={couponData}
                                                setData={setCouponData}
                                                minDate={formik?.values?.startDate}
                                                disabled={!formik?.values?.startDate}
                                                />
                                            </div>
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='input'
                                                type='number' 
                                                label='How many times can use it'
                                                placeholder='Ex : 100'
                                                name='maxUseCount'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                <button 
                                                type="submit" 
                                                className='btn-primary py-3 px-12'
                                                disabled={updateLoading || !formik.isValid}
                                                >
                                                    {
                                                        updateLoading
                                                        ? 
                                                            <ClipLoader size={20} color='white' />
                                                        : 
                                                            'Save'
                                                    }
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }
                            }

                        </Formik>
                    : 
                        <ItemNotFound message='Coupon not found.' />
                }
            </div>
        </Layout>
    )
}

export default EditCoupon;