import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { baseURL } from 'config/api';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { blog_getDetails } from 'redux/actions/blogActions';

const imgDir = '/blogs/'

const BlogDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { docDetails: blog, loading } = useSelector(state => state.blog);

    useEffect(() => {
        if (!blog) {
            dispatch(blog_getDetails(id));
        }
    }, [blog, dispatch, id]);

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Blog Detail' showIcon={false} />
                    </div>
                    <BackBtn />
                </div>
                {
                    loading
                        ?
                        <Loader />
                        :
                        <div className='mt-6 lg:w-[75%] mx-auto'>
                            <img
                                src={baseURL + imgDir + blog?.image}
                                alt={blog?.title}
                                className='w-full h-[300px] object-cover rounded-lg'
                            />
                            <div className='mt-4 border-b pb-4 flex items-center justify-between'>
                                <h1 className='text-2xl font-semibold text-primary'>
                                    {blog?.title}
                                </h1>
                                <p>
                                    {moment(blog?.createdAt).format('DD MMM YYYY')}
                                </p>
                            </div>
                            <div
                                className="blog-content mt-4 text-lg"
                                dangerouslySetInnerHTML={{ __html: blog?.content }}
                            />
                        </div>

                }
            </div>
        </Layout>
    )
}

export default BlogDetails