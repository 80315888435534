import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import { baseURL } from 'config/api'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { withdraw_updateDoc } from 'redux/actions/withdrawActions'
import { setDocDetails } from 'redux/reducers/withdrawReducer'
import fetcher from 'utils/fetcher'
import formatAmount from 'utils/formatAmount'
import SelectBox from 'components/global/SelectBox'
import Textarea from 'components/global/Textarea';
import confirmBox from 'utils/confirmBox'
import RequestStatus from 'components/global/RequestStatus'
import { toast } from 'react-toastify'


const statuses = ['pending' , 'completed' , 'declined'];

const WithdrawDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { docDetails : item , updateLoading } = useSelector(state => state.withdraw);

    const [proof , setProof] = useState();
    const [requestData , setRequestData] = useState({
        status : '' ,
        description : null
    });

    const queryKey = ['fetch-withdraw-details' , id];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/withdraw/${id}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            dispatch(setDocDetails(doc));
            setRequestData({
                status : doc?.status ,
                description : doc?.description || null
            });
        }
    }, [data]);

    const updateHandler = async () => {
        if(requestData?.status === item?.status && requestData?.description === item?.description) {
            return toast.info('You have not made any changes to save.')
        }
        const message = `Are you sure? You want to ${requestData?.status} this request?`
        const onYesClick = () => {
            dispatch(withdraw_updateDoc(id , requestData));
        }
        confirmBox({ message , onYesClick });        
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <Heading title='Withdraw Request Details' showIcon={false} />
                    <BackBtn />
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    item 
                    ?
                    <div className='mt-6'>
                        <div className='shadow-bg p-4 bg-pure'>
                            <div className='flex flex-col gap-4'>
                                
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Username</h6>
                                    <p className={'text-primary'}>
                                        {item?.user?.fullName}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Phone No</h6>
                                    <p className={'text-primary'}>
                                        {item?.user?.phone}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Email</h6>
                                    <p className={'text-primary'}>
                                        {item?.user?.email}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Withdrawal Amount</h6>
                                    <p className='text-primary'>
                                        {formatAmount(item?.withdrawAmount)}
                                    </p>
                                </div>
     
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Bank Name</h6>
                                    <p className='text-primary font-semibold text-lg'>
                                    {item?.withdrawBank?.bankName}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Account Holder</h6>
                                    <p className='text-primary font-semibold text-lg'>
                                        {item?.withdrawBank?.accountHolder}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Account Number</h6>
                                    <p className='text-primary font-semibold text-lg'>
                                        {item?.withdrawBank?.accountNo}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>IFSC_code</h6>
                                    <p className='text-primary font-semibold text-lg'>
                                        {item?.withdrawBank?.IFSC_code || '---'}
                                    </p>
                                </div>
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Initiated At</h6>
                                    <p className='text-primary'>
                                        {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                    </p>
                                </div>
                                {
                                    item?.status === 'completed' && 
                                    <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                        <h6 className='font-medium'>Completed At</h6>
                                        <p className='text-primary'>
                                            {moment(item?.completedAt).format('DD MMM YYYY hh:mm A')}
                                        </p>
                                    </div>
                                }
                                <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                                    <h6 className='font-medium'>Current Status</h6>
                                    <RequestStatus status={item?.status} />
                                </div>

                            </div>
                            <div className='flex flex-col gap-4 mt-4'>
                                {
                                    item?.status === 'pending' && 
                                        <SelectBox
                                        label='Status'
                                        data={requestData}
                                        setData={setRequestData}
                                        name='status'
                                        options={statuses.map(item => ({ label : item , value : item }))}
                                        required
                                        />
                                }
                                <Textarea
                                label='Reason'
                                data={requestData}
                                setData={setRequestData}
                                placeholder='Write reason here...'
                                name='description'
                                />
                                <div className="mt-4">
                                    <button 
                                    className="btn-primary py-2 px-12"
                                    disabled={updateLoading}
                                    onClick={updateHandler}
                                    >
                                        {
                                            updateLoading 
                                            ? 
                                                <ClipLoader size={20} />
                                            : 
                                                'Update'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                        <ItemNotFound message='Withdraw request not found.' />
                } 
            </div>
        </Layout>
    )
}

export default WithdrawDetails














