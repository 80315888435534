import { memo } from 'react';
import Select from 'react-select';

const SelectBox = ( { options , setData , data = '' , name , label , ...props } ) => {
    
    const handleChange = e => {
        setData(prev => ({...prev , [name] : e.value}));      
    }

    const selectedOption = options.find(option => option.value === data[name]);

    return (
        <div className='flex flex-col gap-2 flex-1 w-full'>
            {
                label && 
                <label className='font-semibold text-gray-600 '>
                    {label}
                </label>
            }
            <Select
            options={options}
            className='outline-none w-full'
            inputId='select-box-input'
            name={name}
            onChange={handleChange}
            value={selectedOption}
            {...props}
            />
        </div>
    );
}

export default memo(SelectBox);