import React from 'react'

const LocationLink = ({ location }) => {
    return (
        <a 
        href={`https://www.google.com/maps/search/?api=1&query=${location?.coordinates[1]},${location?.coordinates[0]}`} 
        target="_blank" 
        rel="noopener noreferrer"
        className='underline text-primary'
        >
            {location?.address?.slice(0,15) + '...'}
        </a>
    )
}

export default LocationLink;