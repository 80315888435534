import Pagination from 'components/global/pagination';
import { baseURL } from 'config/api';
import React, { useEffect, useRef, useState } from 'react'
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { blogCategory_delete } from 'redux/actions/blogCategoryActions';
import useClickOutside from 'utils/clickOutside';
import EditBlogCategoryModel from './EditBlogCatModel';
import { setSelectedDoc, setShowEditModel } from 'redux/reducers/blogCategoryReducer';
import confirmBox from 'utils/confirmBox';

const CategoriesTable = () => {
    const dispatch = useDispatch();
    const { docs  , deleteLoading , showEditModel , selectedDoc } = useSelector(state => state.blogCategory);

    
    const deleteHandler = async (doc) => {
        dispatch(setSelectedDoc(doc));
        const message ='Are you sure? You want to delete this category?';
        const onYesClick = () => {
            dispatch(blogCategory_delete(doc?._id));
        };
        confirmBox({ message , onYesClick });
    }

    const editHandler = (doc) => {
        dispatch(setSelectedDoc(doc));
        dispatch(setShowEditModel(true));
        
    }

    
    return (
        <div className="overflow-x-auto rounded-lg">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Name
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Image
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                {
                    docs?.map((doc , i) => (
                        <tr
                        key={doc?._id} 
                        className="bg-white border-b transition duration-300 ease-in-out"
                        >
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {doc?.name}
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            <LazyLoad height={100}>
                                <img 
                                src={baseURL + '/blogCategories/' + doc?.image} 
                                alt="car"
                                className='w-[120px] h-[100px] object-cover rounded-md'
                                />
                            </LazyLoad>
                        </td>
                        
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                            <div className='flex items-center justify-center gap-4'>  
                                <button 
                                className='edit-round'
                                title='Edit Category'
                                onClick={() => editHandler(doc)}
                                >
                                    <i className="uil uil-pen"></i>
                                </button>
                                {
                                    deleteLoading && selectedDoc?._id === doc?._id 
                                    ? 
                                        <ClipLoader size={20} />
                                    : 
                                    <button 
                                    className='delete-round'
                                    title='Delete Category'
                                    onClick={() => deleteHandler(doc)}
                                    >
                                        <i className="uil uil-trash"></i>
                                    </button>
                                }
                            </div>
                        </td>
                    </tr>
                    ))
                }
                
                </tbody>
            </table>

            {
                showEditModel && <EditBlogCategoryModel />
            }
        </div>
    )
}

export default CategoriesTable;