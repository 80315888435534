import * as Yup from 'yup';

const carValidation = Yup.object().shape({
    name: Yup.string()
    .min(1 , 'Name should contain at least 1 characters.')
    .max(100 , 'Name should not exceed 100 characters.')
    .required(),
    make: Yup.string()
    .min(1 , 'value should contain at least 1 characters.')
    .max(100 , 'value should not exceed 100 characters.')
    .required(),
    model: Yup.string()
    .min(1 , 'model should contain at least 1 characters.')
    .max(100 , 'model should not exceed 100 characters.')
    .required(),
    year: Yup.number().required(),
    vehicleType: Yup.string().oneOf([
        'Sedan',
        'SUV',
        'Hatchback',
        'Convertible',
        'Luxury',
        'Sports',
        'Electric',
        'Hybrid',
        'Van',
        'Truck',
        'Minivan'
    ]).required('Vehicle type is required'),
    transmissionType: Yup.string().oneOf(['automatic', 'manual']).required(),
    fuelType: Yup.string().oneOf(["gasoline", "diesel", "electric" , "CNG" , "petrol"]).required(),
    mileage: Yup.string().required(),
    licensePlate: Yup.string().required(),
    seats: Yup.number().integer().required(),
    doors: Yup.number().integer().required(),
    airConditioning: Yup.boolean(),
    audioSystem: Yup.string(),
    images: Yup.array().of(Yup.string()).required(),
    rentPerHour: Yup.number().required(),
    pickupInstructions: Yup.string().max(400 , 'Pickup instructions should not exceed 400 characters.'),
    isActive: Yup.boolean().required(),
    RC: Yup.string().required(),
    POC: Yup.string().required(),
    insurance: Yup.string().required(),  
    images: Yup.array()
    .min(1, 'At least one image is required')
    .required('At least one image is required'),
});

export default carValidation;
