import Axios from 'config/api';
import { setLoading , setCreateLoading, setStats, setRecentLoading, setRecentUnreadDocs, setMarkAsReadLoading, setAllAsRead} from 'redux/reducers/notificationReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const url = '/notification';

export const sendPushNotification = (data , resetForm) => async (dispatch , getState) => {
    dispatch(setCreateLoading(true))
    try {
        const { data : { data : { message } } } = await Axios.post(`${url}/send-push-notification` , data , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        toast.success(message);
        resetForm();
        dispatch(setCreateLoading(false));
    } catch (err) {
        dispatch(setCreateLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

export const notification_getMy = () => async (dispatch , getState) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { docs , page , pages , docsCount } } } = await Axios(`${url}/my` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(setStats({ docs , pages , page , docsCount }))
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

export const notification_getRecentUnread = () => async (dispatch , getState) => {
    dispatch(setRecentLoading(true))
    try {
        const { data : { data : { docs } } } = await Axios(`${url}/recent-unread` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(setRecentUnreadDocs(docs))
        dispatch(setRecentLoading(false));
    } catch (err) {
        dispatch(setRecentLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

export const notification_markAllAsRead = () => async (dispatch , getState) => {
    dispatch(setMarkAsReadLoading(true))
    try {
        const { data : { data : { docs } } } = await Axios(`${url}/mark-all-as-read` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(setAllAsRead())
        dispatch(setMarkAsReadLoading(false));
    } catch (err) {
        dispatch(setMarkAsReadLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

