import SmallCarImg from 'assets/images/smallCar.png';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import OrderStatus from 'components/global/OrderStatus';
import { baseURL } from 'config/api';

const statuses = ['pending' , 'accepted' , 'completed' , 'cancelled']

const BookingItem = ({ booking }) => {
    return (
        <div className='shadow-bg p-4 flex justify-between border hover:border-primary '>
            <div className='flex-[1.5] flex items-center gap-4'>
                {
                    booking?.car?.images?.length > 0 &&
                    <LazyLoad height={100}>
                        <img 
                        src={baseURL + '/cars/' + booking?.car?.images[0]} 
                        alt="car"
                        className='w-[100px] h-[80px] object-cover rounded-md'
                        />
                    </LazyLoad>

                }
                <div>
                    <h3 className='text-[15px] font-semibold mb-1'>
                        {booking?.car?.name}
                    </h3>
                    <Link 
                    to={`/user-management/users/${booking?.customer?._id}`} 
                    className='text-sm text-red-500 hover:underline'
                    >
                        {booking?.customer?.fullName}
                    </Link>
                </div>
            </div>
            <div className='flex-1 flex flex-col gap-3 text-sm'>
                <h3 className='text-[15px] font-semibold'>Amount</h3>
                <p className='text-dark'>
                    <span>&#8377;</span> {booking?.totalPrice}
                </p>
            </div>
            <div className='flex-1 flex flex-col gap-3 text-sm'>
                <h3 className='text-[15px] font-semibold'>Booking Date</h3>
                <p className='text-dark'>
                    {moment(booking?.createdAt).format('DD MMM YYYY')}
                </p>
            </div>
            <div className='flex-1 flex flex-col gap-3 text-sm'>
                <h3 className='text-[15px] font-semibold'>Pickup Date</h3>
                <p className='text-dark'>
                    {moment(booking?.pickupDate).format('DD MMM YYYY hh:mm A')}
                </p>
            </div>
            <div className='flex-1 flex flex-col gap-3 text-sm'>
                <h3 className='text-[15px] font-semibold'>End Date</h3>
                <p className='text-dark'>
                    {moment(booking?.returnDate).format('DD MMM YYYY hh:mm a')}
                </p>
            </div>
            <div className='flex-1 flex flex-col gap-3 text-sm'>
                <h3 className='text-[15px] font-semibold'>Status</h3>
                <p className='text-dark'>
                    <OrderStatus status={booking?.status} />
                </p>
            </div>
            <div className='flex-[0.5] flex flex-col gap-3 text-sm'>
                <h3 className='text-[15px] font-semibold'>View</h3>
                <Link
                to={`/booking-management/booking-details/${booking?._id}`} 
                className='hover:bg-gray-100 underline py-1 px-2 '>
                    Details
                </Link>
            </div>
        </div>
    )
}

export default BookingItem