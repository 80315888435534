import BlogCard from 'components/blogs/BlogCard';
import Categories from 'components/blogs/Categories';
import Filter from 'components/blogs/Filter';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/blogReducer';
import fetcher from 'utils/fetcher';

const Blogs = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs : blogs , currentPage , pages , docsCount , category , keyword , sort } = useSelector(state => state.blog);

    const queryKey = ['fetch-all-blogs' , currentPage , category , keyword , sort ];
    const { isLoading , data }   = useQuery(queryKey , () => {
        return fetcher(`/blog/for-admin?keyword=${keyword}&category=${category}&sort=${sort}` , user);
    } , { refetchOnWindowFocus : false });

    useEffect(() => {
        if(data) {
            const { data : { data : { page , pages , docs , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    } ,[data])


    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Blogs' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <Link to='/blogs/add-new' 
                    className="btn-primary py-2 5 px-6"
                    >
                        Create New Blog
                    </Link>
                </div>
                <div className="mt-8">
                    <Filter />
                    <div>
                        <Categories />
                    </div>
                    {
                        isLoading
                        ? 
                            <Loader />
                        :
                        blogs?.length > 0 
                        ?
                            <div className='mt-12 grid 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8'>
                                {
                                    blogs?.map(item => (
                                        <BlogCard blog={item} />
                                    ))
                                }
                            </div>
                        : 
                            <ItemNotFound message='No Blog found.' />
                    }
                    <div className='mt-10'>
                        <Pagination
                        currentPage={currentPage}
                        pageCount={pages}
                        setPage={setCurrentPage}
                        />
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default Blogs;