import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import Pagination from 'components/global/pagination';
import VendorsTable from 'components/vendorsManagement/VendorsTable';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { vendor_deleteMultipleUsers } from 'redux/actions/vendorActions';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setKeyword, setPages } from 'redux/reducers/vendorReducer';
import confirmBox from 'utils/confirmBox';
import fetcher from 'utils/fetcher';

const Vendors = () => {
    const dispatch = useDispatch();
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [fetch , setFetch] = useState(true);

    const { 
        docs : sellers , currentPage , pages , docsCount  , duration , keyword , deleteLoading
    } = useSelector((state => state.vendor));
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-sellers' , duration , currentPage , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user/all-sellers?page=${currentPage}&duration=${duration}&keyword=${keyword}` , user );
    } , {
        enabled : fetch ,
        refetchOnWindowFocus : true 
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docCount));
        }
    }, [data])

    const deleteVendorsHandler = () => {
        const message = 'Are you sure? You want to delete selected vendors?';
        const onYesClick = () => {
            dispatch(vendor_deleteMultipleUsers({ userIds : selectedVendors }))            
        }
        confirmBox({ message , onYesClick });
    }

    const searchFetcher = async (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <Layout>
            <div className='flex items-center gap-2'>
                <Heading title='All Vendors' showIcon={false} />
                <div className='bg-gray-100 flex items-center justify-center py-1 rounded-md px-3'>
                    {docsCount}
                </div>
            </div>
            <div className='flex items-center justify-between mt-6'>
                <div>
                    <Search  
                    fetcher={searchFetcher}
                    />
                </div>
                <div className='flex items-center gap-4'>
                    <Link to='/vendor-management/add-new'>
                        <button className="btn-primary py-2 px-12">ADD VENDOR</button>
                    </Link>
                </div>
            </div>
            <div className='my-6 shadow-bg '>
                <div className='py-4 flex items-center justify-between px-4'>
                    <div>
                    {
                        selectedVendors.length > 1 && 
                            <button 
                            className="border border-red-500 text-red-600 hover:bg-red-500 duration-300 ease-in-out rounded-md hover:text-pure py-2 px-8 flex items-center gap-2"
                            onClick={deleteVendorsHandler}
                            disabled={deleteLoading}
                            >
                                {
                                    deleteLoading 
                                    ? 
                                        'Deleting...'
                                    : 
                                        <>
                                            <i className="uil uil-trash"></i>
                                            Delete Selected
                                        </>
                                }
                            </button>
                    }
                    </div>
                    <select 
                    className='select-box'
                    onChange={e => dispatch(setDuration(e.target.value))}
                    >
                        <option value="">All</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                        <option value="year">This Year</option>
                    </select>
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    sellers?.length > 0 
                    ? 
                        <div className='overflow-x-auto rounded-lg'>
                            
                            <VendorsTable 
                            selectedVendors={selectedVendors}
                            setSelectedVendors={setSelectedVendors}
                            selectAll={selectAll}
                            setSelectAll={setSelectAll}
                            setFetch={setFetch}
                            />
                            <Pagination
                            currentPage={currentPage}
                            pageCount={pages}
                            setPage={setCurrentPage}
                            />
                                
                        </div>
                    : 
                        <ItemNotFound message='No seller/vendor found.' />
                }
            </div>
        </Layout>
    )
}

export default Vendors;