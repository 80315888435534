import Car from 'components/carsManagement/Car'
import ItemNotFound from 'components/global/ItemNotFound'
import Loader from 'components/global/Loader'
import Search from 'components/global/Search'
import Pagination from 'components/global/pagination'
import { memo, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/carReducer'
import fetcher from 'utils/fetcher'

const VendorCars = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs : cars , pages , currentPage , docsCount , keyword } = useSelector(state => state.car);

    const queryKey = ['fetch-seller-cars' , currentPage , keyword]
    const { isLoading , data } = useQuery(queryKey ,() => fetcher(`/car/seller/${id}` , user));

    useEffect(() => {
        if(data) {
            dispatch(setDocs(data?.data?.data?.docs));
            dispatch(setCurrentPage(data?.data?.data?.page));
            dispatch(setPages(data?.data?.data?.pages));
            dispatch(setDocsCount(data?.data?.data?.docsCount));
        }
    }, [data]);

    return (
        <div className='mt-8'>
            <div className="flex items-center gap-2">
                <h3 className='text-lg font-semibold'>Cars</h3>
                <div className="docs-count">
                    {docsCount}
                </div>
            </div>
            {/* <div className='shadow-bg p-4 mt-4'>
                <div className='w-[300px]'>
                    <Search />
                </div>
            </div> */}
            {
                    isLoading
                    ? 
                        <Loader />
                    : 
                    cars?.length > 0
                    ? 
                        <div>
                            <div className='mt-8 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-8'>
                                {
                                    cars?.map((item , i) => (
                                        <Car key={item?._id} car={item} />
                                    ))
                                }
                            </div>
                            <div className='mt-8'>
                                <Pagination
                                pageCount={pages}
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    :
                        <ItemNotFound message='No item added yet.' />
                    
                }
        </div>
    )
}

export default memo(VendorCars)