import React, { Children } from 'react'
import PopupLayout from './PopupLayout'

const ImagePopup = ({ setShowPopup , img }) => {
    return (
        <PopupLayout
        setShowPopup={setShowPopup}
        >
            <div className=''>
                <img 
                src={img} 
                alt="details" 
                className='w-full h-full max-w-[90vw] max-h-[80vh] object-contain rounded-md'
                />
            </div>
        </PopupLayout>
    )
}



export default ImagePopup