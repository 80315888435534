import Pagination from 'components/global/pagination'
import BookingItem from 'components/bookingManagement/BookingItem'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import fetcher from 'utils/fetcher';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setKeyword, setPages, setStatus } from 'redux/reducers/bookingReducer';
import statuses from 'data/statuses';
import Search from 'components/global/Search';

const VendorBookings = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs : bookings , currentPage , pages , docsCount , status , duration , keyword } = useSelector(state => state.booking);

    const queryKey = ['fetch-seller-bookings' , status , currentPage , keyword];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/booking/seller-bookings/${id}?page=${currentPage}&duration=${duration}&status=${status}&keyword=${keyword}` , user )
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    const searchFetcher = (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <div>
            <div className='flex items-center justify-between gap-4 mt-4'>
                <div className="flex items-center gap-2">
                    <h3 className='text-lg font-semibold '>Bookings</h3>
                    <div className="docs-count">
                        {docsCount}
                    </div>
                </div>
                <div className='flex items-center gap-6'>
                    <Search 
                    fetcher={searchFetcher}
                    />
                    <div className='flex items-center gap-2'>
                        <label className='font-semibold'>Status</label>
                        <select
                        className='select-box'
                        value={status}
                        onChange={(e) => dispatch(setStatus(e.target.value))}
                        >
                            <option value=''>All</option>
                            {
                                statuses?.map(item => (
                                    <option 
                                    key={item} 
                                    value={item}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='flex items-center gap-2'>
                        <label className='font-semibold'>Duration</label>
                        <select 
                        className='select-box'
                        onChange={e => dispatch(setDuration(e.target.value))}
                        value={duration}
                        >
                            <option value=''>All</option>
                            <option value='today'>Today</option>
                            <option value='yesterday'>Yesterday</option>
                            <option value='week'>This Week</option>
                            <option value='month'>This Month</option>
                            <option value='year'>This Year</option>
                        </select>
                    </div>
                </div>
            </div>
            {
                isLoading
                ? 
                    <Loader />
                :
                bookings?.length > 0
                ? 
                    <div className='flex flex-col gap-6 mt-4'>
                        {
                            bookings?.map((item , i) => (
                                <BookingItem 
                                key={item?._id} 
                                booking={item}
                                />
                            ))
                        }
                        <Pagination
                        pageCount={pages}
                        currentPage={currentPage}
                        setPage={setCurrentPage}
                        />
                    </div>
                :
                    <ItemNotFound message='No booking found.' />
            }
        </div>
    )
}

export default memo(VendorBookings)