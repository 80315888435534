import Heading from "components/global/Heading";
import Axios from "config/api";
import { memo, useEffect, useMemo, useRef, useState } from "react";

// Importing core components
import QuillEditor from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { setHomePageContent } from "redux/reducers/slugReducer";
import toastError from "utils/toastError";


const SlugContentForm = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    const { homePageContent } = useSelector(state => state.slug)
    const [content , setContent] = useState(homePageContent?.content || '');
    const [loading , setLoading] = useState(false);

    useEffect(() => {
        if(homePageContent?.content) {
            setContent(homePageContent?.content)
        }
    } , [homePageContent])
    
    const quill = useRef();

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    ['link'],
                    ['clean']
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        []
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];

    const submitHandler = async (e) => {
        e.preventDefault();
        if(!content) return toast.error('Content is required.')
        const body = {content}
        try {   
            setLoading(true);
            const { data : { data : { message , doc } } } = await Axios.post('/slug/save-slug-content' , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            dispatch(setHomePageContent(doc))
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }
    






    return (
        <div className="">
            <Heading title={'Home Page Content'} showIcon={false} />
            <form 
            className={' shadow-bg p-4 relative flex flex-col gap-2 mt-2'}
            onSubmit={submitHandler}
            >
                <label className="label">
                    Content
                </label>
                <QuillEditor
                    ref={(el) => (quill.current = el)}
                    theme="snow"
                    className="!h-full"
                    value={content}
                    formats={formats}
                    modules={modules}
                    onChange={(value) => setContent(value)}
                    required
                />
                <div className="mt-8">
                    <button 
                    className="btn-primary py-2 px-4"
                    type='submit'
                    disabled={loading}
                    >
                        {
                            loading 
                            ? 
                                <ClipLoader size={20} color='white' />
                            : 
                                'Save'
                        }
                    </button>
                </div>
            </form>
        </div>
    );
};

export default memo(SlugContentForm);