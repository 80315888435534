import { Link } from 'react-router-dom';
import EarningImg from 'assets/images/earning.png';
import ScreensImg from 'assets/images/screens.png';
import BookingsImg from 'assets/images/bookings.png';
import VendorsImg from 'assets/images/vendors.png';
import { useSelector } from 'react-redux';
import formatAmount from 'utils/formatAmount';

const VendorStats = ({ seller }) => {
    const { docsCount : bookingsCount } = useSelector(state => state.booking);
    const { docsCount : carsCount } = useSelector(state => state.car);

    return (
        <div>
            <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3'>
                <div className='rounded-[2rem] bg-purple-500 text-white flex py-6 px-6 relative'>
                    <div className='flex-1 flex flex-col justify-between'>
                        <p>Wallet Balance</p>
                        <h3 className='text-3xl font-semibold'>₹ {formatAmount(seller?.wallet?.totalBalance)}</h3>
                        {/* <Link to='/earnings' className='underline text-sm'>
                            View Entire list    
                        </Link>            */}
                    </div>
                    <div className='flex-1'>
                        <img 
                        src={EarningImg} 
                        alt="Total Earnings"
                        className='w-full h-full object-cover' 
                        />
                    </div>
                </div>
                <div className='rounded-[2rem] bg-orange-500 text-white flex py-6 px-6 relative'>
                    <div className='flex-1 flex flex-col justify-between'>
                        <p>Total Earning</p>
                        <h3 className='text-3xl font-semibold'>₹ {seller?.wallet?.totalEarned}</h3>
                        {/* <Link to='/earnings' className='underline text-sm'>
                            View Entire list    
                        </Link>            */}
                    </div>
                    <div className='flex-1'>
                        <img 
                        src={EarningImg} 
                        alt="Total Earnings"
                        className='w-full h-full object-cover' 
                        />
                    </div>
                </div>
                <div className='rounded-[2rem] bg-primary text-white flex py-6 px-6 relative'>
                    
                    <div className='flex-1 flex flex-col justify-between'>
                        <p>Total Bookings</p>
                        <h3 className='text-3xl font-semibold'>
                            {bookingsCount}
                        </h3>
                        {/* <Link to='/earnings' className='underline text-sm'>
                            View Entire list    
                        </Link>            */}
                    </div>
                    <div className='flex-1'>
                        <img 
                        src={ScreensImg} 
                        alt="Total Earnings"
                        className='w-full h-full object-cover' 
                        />
                    </div>
                </div>
                <div className='rounded-[2rem] bg-pink-500 text-white flex py-6 px-6 relative'>
                    
                    <div className='flex-1 flex flex-col justify-between'>
                        <p>Total Cars</p>
                        <h3 className='text-3xl font-semibold'>
                            {carsCount}
                        </h3>
                    </div>
                    <div className='flex-1 flex items-end justify-end'>
                        <img 
                        src={BookingsImg} 
                        alt="Total Earnings"
                        className='w-[100px] h-full object-cover' 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorStats