import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import NotificationItem from 'components/notifications/NotificationItem';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { notification_markAllAsRead } from 'redux/actions/notificationActions';

import { setCurrentPage, setStats } from 'redux/reducers/notificationReducer';
import fetcher from 'utils/fetcher';

const Notifications = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs: notifications, currentPage, pages, markAsReadLoading } = useSelector(state => state.notification);

    const queryKey = ['fetch-notifications', currentPage]
    const { isLoading, data } = useQuery(queryKey, () => {
        return fetcher(`/notification/my?page=${currentPage}`, user);
    });

    useEffect(() => {
        if (data) {
            const { data: { data: { docs, page, pages, docsCount } } } = data;
            dispatch(setStats({ docs, page, pages, docsCount }))
        }
    }, [data]);


    const markAllAsReadHandler = () => {
        dispatch(notification_markAllAsRead());
    }

    return (
        <Layout>
            <div className='pb-12'>
                <div>
                    <BackBtn />
                </div>
                <div className='flex justify-between items-center mt-6'>
                    <Heading title='Notifications' icon='bell' />
                    <button
                        className='btn-primary py-1.5 px-8'
                        disabled={markAsReadLoading}
                        onClick={markAllAsReadHandler}
                    >
                        {
                            markAsReadLoading
                            ? 
                                <ClipLoader size={20} color='white' />
                            : 
                                'Mark all as read'
                        }
                    </button>
                </div>
                <div className='flex flex-col gap-4 mt-8'>
                    {
                        isLoading
                            ?
                            <Loader />
                            :
                            notifications?.length > 0
                                ?
                                <>
                                    {
                                        notifications?.map(item => (
                                            <NotificationItem key={item?._id} item={item} />
                                        ))
                                    }
                                    <Pagination
                                        pageCount={pages}
                                        currentPage={currentPage}
                                        setPage={setCurrentPage}
                                    />
                                </>
                                :
                                <ItemNotFound message='No notification found' />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Notifications