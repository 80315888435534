import AvatarImg from 'assets/images/avatar.png';
import { baseURL } from 'config/api';

const VendorDetails = ({ booking }) => {

    return (
        <div className='shadow-bg p-4'>
            <div>
                <h3 className="text-lg font-semibold text-primary">
                    Vendor Details
                </h3>
            </div>
            <div className='mt-6'>
                <div className='flex items-center justify-center'>
                    <img 
                    src={booking?.seller?.image ? baseURL + '/users/' + booking?.seller?.image : AvatarImg} 
                    alt="avatar"
                    className='w-[80px] h-[80px] object-cover'
                     />
                </div>
                <div className='mt-8 flex flex-col gap-3'>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Name :
                        </h6>
                        <span className='text-gray-600'>
                            {booking?.seller?.fullName || 'delete'}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Email :
                        </h6>
                        <span className='text-gray-600'>
                            {booking?.seller?.email || 'deleted'}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Phone :
                        </h6>
                        <span className='text-gray-600'>
                            {booking?.seller?.phone || 'deleted'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorDetails