import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Filters from 'components/withdrawRequests/Filters';
import WithdrawRequestsTable from 'components/withdrawRequests/WithdrawRequestsTable';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setDocs, setDocsCount, setPages , setCurrentPage} from 'redux/reducers/withdrawReducer'
import fetcher from 'utils/fetcher'

const WithdrawRequests = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , keyword , status  } = useSelector(state => state.withdraw);

    const queryKey = ['fetch-user-withdrawals' , currentPage , status , keyword];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/withdraw?keyword=${keyword}&page=${currentPage}&status=${status}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , page , pages } } } = data;
            dispatch(setDocsCount(docsCount));
            dispatch(setDocs(docs));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
        }
    }, [data]);


    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='Withdraw Requests' icon='export' />
                <BackBtn />
            </div>
            <div className='flex flex-col gap-6 mt-4'>
                <div>
                    <Filters />
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    docs?.length > 0 
                    ? 
                        <WithdrawRequestsTable />
                    : 
                        <ItemNotFound message='No withdraw history found.' />
                } 
            </div>
        </Layout>
    )
}

export default WithdrawRequests;