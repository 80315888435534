import CouponsTable from 'components/coupons/CouponsTable'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Pagination from 'components/global/pagination'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/couponReducer'
import fetcher from 'utils/fetcher'

const Coupons = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs : coupons , currentPage , pages , docsCount } = useSelector(state => state.coupon);

    const queryKey = ['fetch-all-coupons' , currentPage ];
    const { isLoading , data }   = useQuery(queryKey , () => {
        return fetcher(`/coupon` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { page , pages , docs , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docCount));
        }
    } ,[data])

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Coupons' icon='medal' />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <Link to='/coupons/add-new' className="btn-primary py-2 5 px-6">
                        <button>Add Coupon</button>
                    </Link>
                </div>
                <div className='mt-8'>
                    {
                        isLoading 
                        ? 
                            <Loader /> 
                        : 
                        coupons?.length > 0 
                        ? 
                            <div className='shadow-bg '>
                                <CouponsTable />
                                <Pagination
                                currentPage={currentPage}
                                pageCount={pages}
                                setPage={setCurrentPage}
                                />
                            </div>
                        : 
                            <ItemNotFound message='Coupon not found.' />
                    }
                </div>

            </div>
        </Layout>
    )
}

export default Coupons