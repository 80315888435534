import * as Yup from 'yup';

const editUserValidation = Yup.object({
    fullName: Yup.string()
        .min(3, 'Name should have at least 3 characters')
        .max(30, 'Name should not exceed 50 characters')
        .required('Name is required'),
  
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ ,
            'Invalid email address.'
        ),
  
    phone : Yup.string()
        .min(10, 'Phone should have at least 10 numbers')
        .max(10, 'Phone should not exceed 10 numbers')
        .required('Phone is required')
        .matches(
            /^[6-9]\d{9}$/, 
            'Invalid phone no.'
        ) ,
});

export default editUserValidation;