import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import UsersTable from 'components/userManagement/UsersTable';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setKeyword, setPages } from 'redux/reducers/userReducer';
import fetcher from 'utils/fetcher';
import toastError from 'utils/toastError';
import Pagination from 'components/global/pagination';
import ItemNotFound from 'components/global/ItemNotFound';
import { user_deleteMultipleUsers } from 'redux/actions/userActions';
import { ClipLoader } from 'react-spinners';
import confirmBox from 'utils/confirmBox';


const Users = () => {
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const { 
        docs : users , currentPage , pages , docsCount  , duration , keyword , deleteLoading
    } = useSelector((state => state.user));
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-users' , duration , currentPage , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user/all-users?page=${currentPage}&duration=${duration}&keyword=${keyword}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    const deleteUsersHandler = () => {
        const message = 'Are you sure? You want to delete selected users?'
        const onYesClick = () => {
            dispatch(user_deleteMultipleUsers({ userIds : selectedUsers } , setSelectedUsers , setSelectAll))
        };
        
        confirmBox({ message , onYesClick });
    }

    const searchFetcher = async (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <Layout>
            <div className='flex items-center gap-2'>
                <Heading title='User Management' showIcon={false} />
                <div className='docsCount'>
                    {docsCount}
                </div>
            </div>
            <div className='flex items-center justify-between mt-6'>
                <div>
                    <Search  
                    fetcher={searchFetcher}
                    />
                </div>
                <div className='flex items-center gap-4'>
                    <Link to='/user-management/add-new'>
                        <button className="btn-primary py-2 px-12">ADD USER</button>
                    </Link>
                </div>
            </div>
            <div className='my-6 shadow-bg'>
                <div className='py-4 flex items-center justify-between px-4'>
                    <div>
                    {
                        selectedUsers.length > 1 && 
                            <button 
                            className="border border-red-500 text-red-600 hover:bg-red-500 duration-300 ease-in-out rounded-md hover:text-pure py-2 px-8 flex items-center gap-2"
                            onClick={deleteUsersHandler}
                            disabled={deleteLoading}
                            >
                                {
                                    deleteLoading
                                    ? 
                                        <ClipLoader size={15}  />
                                    : 
                                        <>
                                            <i className="uil uil-trash"></i>
                                            Delete Selected
                                        </>
                                }
                            </button>
                    }
                    </div>
                    <select 
                    className='select-box'
                    onChange={e => dispatch(setDuration(e.target.value))}
                    >
                        <option value="">All</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    users?.length > 0
                    ?
                        <div className=''>
                            <div className='overflow-x-auto'>
                                <UsersTable 
                                selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers}
                                selectAll={selectAll}
                                setSelectAll={setSelectAll}
                                />
                            </div>

                            <Pagination 
                            currentPage={currentPage}
                            pageCount={pages}
                            setPage={setCurrentPage}
                            />
                                
                        </div>
                    : 
                        <ItemNotFound message='User Not Found.' />
                }
            </div>
        </Layout>
    )
}

export default Users;