import React from 'react';
import { baseURL } from 'config/api';
import { Link, useNavigate } from 'react-router-dom';


const TopSellings = ({ topCars }) => {
    const navigate = useNavigate();

    return (
        <div>
            <div className=' flex items-center justify-between border-b pb-2'>
                <div className='flex items-center gap-2 text-lg text-grayText'>
                    <i className="uil uil-chart text-xl"></i>
                    <h4 className='font-semibold'>Top Bookings Cars</h4>
                </div>
                <div className='text-5xl text-grayText'>
                    <i className="uil uil-gift"></i>
                </div>
            </div>
            <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 py-8'>
                {
                    topCars?.map((item , i) => (
                        <Link
                        to={`/cars-management/details/${item?._id}`} 
                        className='border-2 rounded-lg py-2 px-4 flex flex-col gap-2 hover:border-primary cursor-pointer' 
                        key={item?._id}
                        >
                            <div className='bg-orange-600 w-fit text-pure rounded-md py-1 px-4 text-[10px]'>
                                Bookings {item?.totalBookings}
                            </div>
                            <div className='w-full h-[120px] py-2'>
                                <img 
                                src={baseURL + '/cars/' + item?.images[0]} 
                                alt={item?.name} 
                                className='w-full object-contain h-[100px]'
                                />
                            </div>
                            <div className='text-center text-sm text-dark font-medium'>
                                {item?.name} , {item?.transmissionType}
                            </div>
                            
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default TopSellings