import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import Pagination from 'components/global/pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setKeyword, setPages, setStatus } from 'redux/reducers/kycReducer';
import KycTable from 'components/kyc/KycTable';


const statuses = ['on hold' , 'pending' , 'approved' , 'declined']

const Kyc = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , pages , docsCount , status , keyword } = useSelector(state => state.kyc);

    const queryKey = ['fetch-all-kycs' , currentPage , status  , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/kyc?page=${currentPage}&status=${status}&keyword=${keyword}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
            dispatch(setDocsCount(docCount));
        }
    }, [data])
    
    
    const searchFetcher = (value) => {
        dispatch(setKeyword(value));
    }

    return (
        <Layout>
            <div className='pb-12'>
                <div>
                    <BackBtn />
                </div>
                <div className='flex items-center justify-between gap-4 mt-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Kyc Requests' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <div className='flex items-center gap-6'>
                        <Search 
                        fetcher={searchFetcher}
                        />
                        <div className='flex items-center gap-2'>
                            <label className='font-semibold'>Status</label>
                            <select
                            className='select-box'
                            value={status}
                            onChange={(e) => dispatch(setStatus(e.target.value))}
                            >
                                <option value=''>All</option>
                                {
                                    statuses?.map(item => (
                                        <option 
                                        key={item} 
                                        value={item}>
                                            {item}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    
                </div>
                
                <div className='mt-14'>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        docs?.length > 0 
                        ? 
                            <KycTable />
                        : 
                            <ItemNotFound />
                    } 
                </div>
            </div>
        </Layout>
    )
}

export default Kyc