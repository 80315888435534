import PopupLayout from 'components/global/PopupLayout'
import FormControl from 'components/global/form/FormControl';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { kyc_updateDoc } from 'redux/actions/kycActions';
import confirmBox from 'utils/confirmBox';
import * as Yup from 'yup';

const initValues = { reason : '' };

const validations = Yup.object({
    reason: Yup.string()
        .required('reason is required')
});

const ReasonPopup = ({ setShowPopup , status , kycId  }) => {

    const dispatch = useDispatch();
    const { updateLoading  } = useSelector(state => state.kyc);

    const handleSubmit = async (formData) => {
        const data = { reason : formData?.reason , status };
        dispatch(kyc_updateDoc(kycId , data , setShowPopup));
    }

    return (
        <PopupLayout
        setShowPopup={setShowPopup}
        >
            <div>
                <center className='text-xl font-semibold text-gradient'>
                    Add Reason
                </center>
                <div className="mt-4" />
                <Formik
                initialValues={initValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
                >   
                    {
                        (formik) => {
                            return (
                                <Form>
                                    <div className='flex items-center justify-center'>
                                        <FormControl
                                        control='textarea'
                                        label='Reason'
                                        placeholder='Write reason here'
                                        name='reason'
                                        formik={formik}
                                        />
                                    </div>
                                    <center className='mt-8'>
                                        <button 
                                        className="btn-primary py-2 sm:px-12 px-6"
                                        disabled={updateLoading || !formik.isValid}
                                        type='submit'
                                        >
                                            {
                                                updateLoading 
                                                ?
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Submit'
                                            }
                                        </button>
                                    </center>
                                </Form>
                            )
                        }
                    }

                </Formik>
            </div>
        </PopupLayout>
    )
}

export default ReasonPopup;