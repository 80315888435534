import PasswordInput from 'components/global/PasswordInput';
import FormControl from 'components/global/form/FormControl';
import Axios from 'config/api';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

import * as Yup from 'yup';

const initValues = { 
    newPassword : '' , 
    confirmPassword : ''
}

const validation = Yup.object({
    newPassword: Yup.string()
        .min(8, 'Password should have at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character'
        )
        .required('New Password is required'),

    confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
})

const ResetPassword = () => {
    const navigate = useNavigate();
    const [_data, setData] = useState({ 
        otp : localStorage.getItem('password-otp') || '' ,
        newPassword : '' , 
        confirmPassword : ''
     });
    const [loading , setLoading] = useState(false)


    const handleSubmit = async (formData, onSubmitProps) => {
        const { newPassword , confirmPassword } = formData;
        if(newPassword !== confirmPassword) {
            return toast.error('Passwords are not matched.')
        }
        const body = {
            ...formData ,
            otp : localStorage.getItem('password-otp') || ''
        }
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.put(`/user/reset-password` , body );
            toast.success(message);
            localStorage.removeItem('password-otp');
            onSubmitProps.resetForm();
            navigate('/login');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full min-h-screen bg-gradient">
            <div className='shadow-bg bg-pure p-4 sm:w-[600px] w-full'>
                <center>
                    <h1 className='text-2xl font-bold'>Reset Password</h1>
                </center>
                <Formik
                initialValues={initValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
                >
                    {
                        (formik) => {
                            return (
                                <Form
                                className='mt-8'
                                >
                                    <div className='flex items-center justify-center flex-col gap-4'>
                                        <FormControl
                                        control='password'
                                        label='New Password'
                                        placeholder='Ex : **************'
                                        name='newPassword'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='password'
                                        label='Confirm Password'
                                        placeholder='Ex : **************'
                                        name='confirmPassword'
                                        formik={formik}
                                        />
                                    </div>
                                    <center className='mt-8'>
                                        <button 
                                        className="btn-primary py-2 sm:px-12 px-6"
                                        disabled={loading}
                                        type='submit'
                                        >
                                            {
                                                loading 
                                                ?
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Update Password'
                                            }
                                        </button>
                                    </center>

                                </Form>
                            )
                        }
                    }

                </Formik>
                <div className='text-sm text-dark mt-4 text-center flex items-center justify-center gap-1'>
                    <button 
                    className=' underline'
                    onClick={() => navigate('/login')}
                    type='button'
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;