import Pagination from 'components/global/pagination';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { coupon_delete } from 'redux/actions/couponActions';
import useClickOutside from 'utils/clickOutside';

const CouponsTable = () => {
    const dispatch = useDispatch();
    const dropMenuRef = useRef(null);
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    const { docs : coupons , deleteLoading } = useSelector(state => state.coupon);

    useClickOutside(dropMenuRef , () => setShowDropMenu(false));
    
    const deleteHandler = async (id) => {
        await dispatch(coupon_delete(id));
        setShowDropMenu(false);
    }
    
    return (
        <div className="overflow-x-auto rounded-lg">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Name
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Coupon Type
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Discount Type
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Discount
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Min Booking Amount
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Status
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                {
                    coupons?.map((coupon , i) => (
                        <tr
                        key={coupon?._id} 
                        className="bg-white border-b transition duration-300 ease-in-out"
                        >
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {coupon?.code}
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {coupon?.couponType}
                        </td>
                        <td className=" text-gray-900 px-6 text-center py-4 whitespace-nowrap">
                            {coupon?.discountType}
                        </td>
                        <td className=" text-gray-900 text-center px-6 py-4 whitespace-nowrap">
                            {coupon?.discountType === 'amount' ? coupon?.discount + ' RS' : coupon?.discount + '%'}
                        </td>
                        <td className=" text-gray-900 text-center px-6 py-4 whitespace-nowrap">
                            {coupon?.minBookingAmount}
                        </td>
                        <td className={`${coupon?.isActive ? 'text-green-500' : 'text-red-500'}  px-6 py-4 whitespace-nowrap font-semibold`}>
                            {coupon?.isActive ? 'Active' : 'Not Active'}
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                            <div className='flex items-end justify-center relative' 
                            >  
                                <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowDropMenu(prev => !prev);
                                    setSelectedMenuIndex(i);
                                }}
                                >
                                    <div><i className="uil uil-setting"></i></div>
                                    <div><i className="uil uil-angle-down"></i></div>
                                </div>
                                {/* DROP MENU */}
                                {   
                                    showDropMenu && selectedMenuIndex === i &&
                                    <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                    ref={dropMenuRef}
                                    >
                                        <Link 
                                        to={`/coupons/edit/${coupon?._id}`} 
                                        className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'>
                                            <span>Edit</span>
                                        </Link>
                                        <button 
                                        className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'
                                        disabled={deleteLoading}
                                        onClick={() => deleteHandler(coupon?._id)}
                                        >
                                            {
                                                deleteLoading
                                                ? 
                                                    <ClipLoader size={15} />
                                                : 
                                                    <span>Delete</span>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>
                    ))
                }
                
                </tbody>
            </table>
        </div>
    )
}

export default CouponsTable;