import LocationLink from 'components/global/LocationLink';
import RequestStatus from 'components/global/RequestStatus';
import moment from 'moment';
import React, { memo } from 'react'
import formatAmount from 'utils/formatAmount';

const BookingInfo = ({ booking }) => {
    return (
        <div className='shadow-bg p-4'>
            <div>
                <h3 className="text-lg font-semibold text-primary">
                    Booking Info
                </h3>
                <div className='mt-4 flex justify-between'>
                    <div className='flex flex-col gap-2 text-sm'>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[120px]'>Pickup Date : </h3>
                            <p className='mt-1'>
                                {moment(booking?.pickupDate).format('DD MMM YYYY hh:mm A')}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[120px] '>
                                Drop off Date :
                            </h3>
                            <p className='text-sm mt-1'>
                                {moment(booking?.returnDate).format('DD MMM YYYY hh:mm A')}
                            </p>
                        </div>
                        
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[120px] '>
                                Total Hours :
                            </h3>
                            <p className='text-sm mt-1'>
                                {booking?.duration?.totalHours}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[120px] '>
                                Total Duration :
                            </h3>
                            <p className='text-sm mt-1'>
                                {booking?.duration?.days + 'd ' + booking?.duration?.hours + 'hr ' + booking?.duration?.minutes + 'min '}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 text-sm'>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[120px]'>Pickup Point : </h3>
                            <div>
                                <LocationLink 
                                location={booking?.car?.location}
                                />
                            </div>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[120px] '>
                                Drop off point :
                            </h3>
                            <div>
                                <LocationLink 
                                location={booking?.car?.dropOffLocation}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-6'>
                <h3 className="text-lg font-semibold text-primary">
                    Price Details
                </h3>
                <div className='mt-4 flex lg:flex-row flex-col gap-8 justify-between'>
                    <div className='flex flex-col gap-2 text-sm'>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px]'>
                                Payment Method : </h3>
                            <p className='mt-1 text-primary font-semibold'>
                                {booking?.paymentMethod}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px]'>
                                Payment Status : </h3>
                            <p className='mt-1'>
                                <RequestStatus status={booking?.paymentStatus} />
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px] '>
                                vehicle Rent :
                            </h3>
                            <p className='text-sm mt-1'>
                                {formatAmount(booking?.vehicleRent)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px] '>
                                Insurance Cost :
                            </h3>
                            <p className='text-sm mt-1'>
                                {formatAmount(booking?.insuranceCost)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px]'>
                                Driver Rent : </h3>
                            <p className='mt-1'>
                                {formatAmount(booking?.driverRent)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px]'>
                                Security Fee : </h3>
                            <p className='mt-1'>
                                {formatAmount(booking?.securityFee)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[180px]'>
                                GST : </h3>
                            <p className='mt-1'>
                                {formatAmount(booking?.GST)}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[200px]'>Grand Total :</h3>
                            <p className='text-xl font-bold '>
                                &#8377; {formatAmount(booking?.totalPrice)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[200px]'>Discount :</h3>
                            <p className='text-xl font-bold '>
                                &#8377; {formatAmount(booking?.discountDetails?.discount)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[200px]'>Paid Amount :</h3>
                            <p className='text-xl font-bold  text-green-500'>
                                &#8377; {formatAmount(booking?.amountPaid)}
                            </p>
                        </div>
                        <div className='flex items-center gap-4'>
                            <h3 className='font-semibold w-[200px]'>Remaining Amount :</h3>
                            <p className='text-xl font-bold text-red-500'>
                                &#8377; {formatAmount(booking?.remainingAmount)}
                            </p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default memo(BookingInfo);