import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { coupon_createDoc } from 'redux/actions/couponActions';
import { ClipLoader } from 'react-spinners';
import { Form, Formik } from 'formik';
import couponValidation from 'validations/CouponValidations';
import FormControl from 'components/global/form/FormControl';

const initValues = {
    code : '' , 
    couponType : 'firstBooking' , 
    discountType : 'percentage' , 
    discount : '' , 
    minBookingAmount : '' , 
    startDate : '' , 
    endDate : '' , 
    isActive : true , 
    maxUseCount : 10
}

const AddCoupon = () => {
    const [couponData , setCouponData] = useState(initValues);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { createLoading } = useSelector(state => state.coupon);

    const submitHanlder = async formData => {
        dispatch(coupon_createDoc(formData , navigate));
    }
    

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Create New Coupon' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                <Formik
                initialValues={initValues}
                validationSchema={couponValidation}
                onSubmit={submitHanlder}
                >
                    {
                        formik => {
                            return (
                                <Form
                                className='shadow-bg p-4 mt-4 flex flex-col gap-4'
                                >
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl
                                        control='input'
                                        label='Coupon Code'
                                        placeholder='Ex: test110'
                                        name='code'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='select'
                                        options={[
                                            { key : 'First Booking' , value : 'firstBooking' } , 
                                            { key : 'Default' , value : 'default' } , 
                                        ]}
                                        label='Coupon Type'
                                        name='couponType'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl
                                        control='select'
                                        options={[
                                            { key : 'Percentage' , value : 'percentage' } , 
                                            { key : 'Amount' , value : 'amount' } , 
                                        ]}
                                        label='Discount Type'
                                        name='discountType'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='input'
                                        type='number'
                                        label='Discount'
                                        name='discount'
                                        placeholder='Enter discount value'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl
                                        control='input'
                                        label='Min Booking Amount'
                                        placeholder='Minimum order price'
                                        name='minBookingAmount'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='select'
                                        options={[
                                            { key : 'Active' , value : true } , 
                                            { key : 'inActive' , value : false } , 
                                        ]}
                                        label='Status'
                                        name='isActive'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl
                                        control='date'
                                        label='Start Date'
                                        placeholderText='Select start date'
                                        name='startDate'
                                        formik={formik}
                                        minDate={new Date()}
                                        />
                                        <FormControl
                                        control='date'
                                        label='End Date'
                                        name='endDate'
                                        placeholderText='Select coupon end date'
                                        data={couponData}
                                        setData={setCouponData}
                                        minDate={formik?.values?.startDate}
                                        disabled={!formik?.values?.startDate}
                                        />
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl
                                        control='input'
                                        type='number' 
                                        label='How many times can use it'
                                        placeholder='Ex : 100'
                                        name='maxUseCount'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <button 
                                        type="submit" 
                                        className='btn-primary py-3 px-12'
                                        disabled={createLoading || !formik.isValid}
                                        >
                                            {
                                                createLoading 
                                                ? 
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Create Coupon'
                                            }
                                        </button>
                                    </div>

                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </Layout>
    )
}

export default AddCoupon;