import * as Yup from 'yup';

const notificationValidation = Yup.object({
    title: Yup.string()
        .min(3, 'Title should have at least 3 characters')
        .max(50, 'Title should not exceed 50 characters')
        .required('Title is required'),
    type : Yup.string().oneOf(['all', 'customer' , 'seller']).required() ,
    description  : Yup.string()
        .max(500, 'Message should not exceed 500 characters')
        .required('Message is required'),
});

export default notificationValidation;