import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import Pagination from 'components/global/pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BookingItem from 'components/bookingManagement/BookingItem';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setKeyword, setPages, setStatus } from 'redux/reducers/bookingReducer';
import statuses from 'data/statuses';


const Bookings = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs : bookings , currentPage , pages , docsCount , status , duration , keyword } = useSelector(state => state.booking);

    const queryKey = ['fetch-all-bookings' , currentPage , status , duration , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/booking?page=${currentPage}&duration=${duration}&status=${status}&keyword=${keyword}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
            dispatch(setDocsCount(docCount));
        }
    }, [data])
    
    
    const searchFetcher = (value) => {
        dispatch(setKeyword(value));
    }

    return (
        <Layout>
            <div className='pb-12'>
                <div>
                    <BackBtn />
                </div>
                <div className='flex items-center justify-between gap-4 mt-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='All Bookings' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <div className='flex items-center gap-6'>
                        <Search 
                        fetcher={searchFetcher}
                        />
                        <div className='flex items-center gap-2'>
                            <label className='font-semibold'>Status</label>
                            <select
                            className='select-box'
                            value={status}
                            onChange={(e) => dispatch(setStatus(e.target.value))}
                            >
                                <option value=''>All</option>
                                {
                                    statuses?.map(item => (
                                        <option 
                                        key={item} 
                                        value={item}>
                                            {item}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='flex items-center gap-2'>
                            <label className='font-semibold'>Duration</label>
                            <select 
                            className='select-box'
                            onChange={e => dispatch(setDuration(e.target.value))}
                            value={duration}
                            >
                                <option value=''>All</option>
                                <option value='today'>Today</option>
                                <option value='yesterday'>Yesterday</option>
                                <option value='week'>This Week</option>
                                <option value='month'>This Month</option>
                                <option value='year'>This Year</option>
                            </select>
                        </div>
                    </div>
                    
                </div>
                
                <div className='mt-14'>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        bookings?.length > 0 
                        ? 
                            <div className='flex flex-col gap-6'>
                                {
                                    bookings?.map((item , i) => (
                                        <BookingItem 
                                        key={i} 
                                        booking={item} 
                                        />
                                    ))
                                }
                                <Pagination 
                                pageCount={pages}
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                />
                            </div>
                        : 
                            <ItemNotFound />
                    } 
                </div>
            </div>
        </Layout>
    )
}

export default Bookings