import Layout from 'components/global/Layout';
import UpdatePassword from 'components/profile/UpdatePassword';

const PasswordSetup = () => {
    return (
        <Layout>
            <div className='pb-12'>
                <UpdatePassword />
            </div>
        </Layout>
    )
}

export default PasswordSetup