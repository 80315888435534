import Car from 'components/carsManagement/Car'
import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Search from 'components/global/Search'
import Pagination from 'components/global/pagination'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setCurrentPage, setDocs, setDocsCount, setPages , setKeyword, setStatus } from 'redux/reducers/carReducer'
import fetcher from 'utils/fetcher';

const carStatuses = ['pending' , 'approved' , 'declined'];

const Cars = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs : cars , status , pages , currentPage , docsCount , keyword } = useSelector(state => state.car);

    const queryKey = ['fetch-cars' , currentPage , keyword , status]
    const { isLoading , data } = useQuery(queryKey ,() => fetcher(`/car/for-admin?page=${currentPage}&keyword=${keyword}&status=${status}` , user));

    useEffect(() => {
        if(data) {
            dispatch(setDocs(data?.data?.data?.docs));
            dispatch(setCurrentPage(data?.data?.data?.page));
            dispatch(setPages(data?.data?.data?.pages));
            dispatch(setDocsCount(data?.data?.data?.docCount));
        }
    }, [data]);

    const searchFetcher = (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <Layout>
            <div className='pb-12'>
                <div>
                    <BackBtn />
                </div>
                <div className='flex items-center justify-between gap-4 mt-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='All Cars' showIcon={false} />
                        <div className='docsCount'>
                            {docsCount}
                        </div>
                    </div>
                    <Link to='/cars-management/add-new'>
                        <button className="btn-primary py-2 px-12">
                            Add New
                        </button>
                    </Link>
                </div>
                <div className='shadow-bg p-4 mt-8 flex items-center justify-between lg:flex-row flex-col-reverse gap-4'>
                    <div className='w-[300px]'>
                        <Search 
                        fetcher={searchFetcher}
                        />
                    </div>
                    <select
                    onChange={(e) => dispatch(setStatus(e.target.value))}
                    value={status}
                    className='select-box w-fit'
                    >
                        <option value="">All</option>
                        {
                            carStatuses.map(item => (
                                <option value={item} key={item}>
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>
                {
                    isLoading
                    ? 
                        <Loader />
                    : 
                    cars?.length > 0
                    ? 
                        <div>
                            <div className='mt-8 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-8'>
                                {
                                    cars?.map((item , i) => (
                                        <Car key={item?._id} car={item} />
                                    ))
                                }
                            </div>
                            <div className='mt-8'>
                                <Pagination
                                pageCount={pages}
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    :
                        <ItemNotFound message='No item added yet.' />
                    
                }
            </div>
        </Layout>
    )
}

export default Cars