import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import SlugsTable from 'components/slugManagement/slugsTable';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDocs, setDocsCount, setPages, setCurrentPage, setKeyword, setHomePageContent } from 'redux/reducers/slugReducer';
import fetcher from 'utils/fetcher';
import SlugContentForm from './SlugContentForm';

const Slugs = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs: slugs, currentPage, pages, docsCount , keyword } = useSelector(state => state.slug);

    const queryKey = ['fetch-slugs', currentPage, keyword]
    const { isLoading, data } = useQuery(queryKey, () => {
        return fetcher(`/slug?page=${currentPage}&keyword=${keyword}`, user);
    });

    useEffect(() => {
        if (data) {
            const { data: { data: { docs, page, pages, docsCount , homePageContent } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
            dispatch(setHomePageContent(homePageContent));
        }
    }, [data]);


    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Slugs' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <Link
                        to='/slugs/add-new'
                        className="btn-primary py-2 5 px-6"
                    >
                        <button>Add New Slug</button>
                    </Link>
                </div>
                <div className="mt-8">
                    {
                        isLoading
                            ?
                            <Loader />
                            :
                            slugs?.length > 0
                            ?
                                <div className='shadow-bg '>
                                    <SlugsTable />
                                    <Pagination
                                    pageCount={pages}
                                    currentPage={currentPage}
                                    setPage={setCurrentPage}
                                    redux
                                    />
                                </div>
                            :
                                <ItemNotFound message='No slug found.' />
                    }
                </div>

                <div className='mt-8'>
                    <SlugContentForm />
                </div>

            </div>
        </Layout>
    )
}

export default Slugs