import NameInput from 'components/global/NameInput';
import FormControl from 'components/global/form/FormControl';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { sendPushNotification } from 'redux/actions/notificationActions';
import notificationValidation from 'validations/notificationValidation';

const notificationsTypes = [
    { value : 'all' , key : 'All'} , 
    { value : 'seller' , key : 'Seller'} , 
    { value : 'customer' , key : 'Customer'} , 
];

const initValues = {
    title : '' ,
    description : '' , 
    type : 'all'
}

const NotificationForm = () => {
    const dispatch = useDispatch();

    const { createLoading } = useSelector(state => state.notification)
   
    const submitHandler = (formData , { resetForm }) => {
        dispatch(sendPushNotification(formData , resetForm));
    }

    return (
        <div>
            <Formik
            initialValues={initValues}
            validationSchema={notificationValidation}
            onSubmit={submitHandler}
            >
                {
                    formik => {
                        return (
                            <Form 
                            className='flex flex-col gap-4 shadow-bg p-4'
                            >
                                <FormControl
                                control='input' 
                                label="Title" 
                                placeholder="New Notification"
                                name='title'
                                formik={formik}
                                />
                                <FormControl
                                control='select' 
                                label="Type" 
                                options={notificationsTypes?.map(item => {
                                    return { key : item.key , value : item.value }
                                })}
                                name='type'
                                formik={formik}
                                />
                                <FormControl
                                control='textarea' 
                                label="Description" 
                                placeholder='Write here...'
                                name='description'
                                formik={formik}
                                />
                                <div className='mt-4'>
                                    <button 
                                    type='submit' 
                                    className='btn-primary py-2 px-12'
                                    disabled={createLoading || !formik.isValid}
                                    >
                                        {
                                            createLoading 
                                            ? 
                                                <ClipLoader size={20} color='white' />
                                            : 
                                                'Send'
                                        } 
                                    </button>
                                </div>
                            </Form> 
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default NotificationForm;


