import * as Yup from 'yup';

const settingsValidation = Yup.object().shape({
    contactNo: Yup.string()
        .trim()
        .min(10, 'Contact number should have at least 10 numbers')
        .max(10, 'Contact number should not exceed 10 numbers')
        .matches(/^[6-9]\d{9}$/, 'Contact number is not valid.')
        .required('Contact number is required'),
    whatsAppNo: Yup.string()
        .trim()
        .min(10, 'Whatsapp number should have at least 10 numbers')
        .max(10, 'Whatsapp number should not exceed 10 numbers')
        .matches(/^[6-9]\d{9}$/, 'WhatsApp number is not valid.')
        .required('WhatsApp number is required'),
    youtubeLink: Yup.string()
        .trim()
        .url('Invalid YouTube link format')
        .required('YouTube link is required'),
    twitterLink: Yup.string()
        .trim()
        .url('Invalid Twitter link format')
        .required('Twitter link is required'),
    facebookLink: Yup.string()
        .trim()
        .url('Invalid Facebook link format')
        .required('Facebook link is required'),
    instagramLink: Yup.string()
        .trim()
        .url('Invalid Instagram link format')
        .required('Instagram link is required'),
    serviceCharges: Yup.number()
        .typeError('Service charges must be a number')
        .positive('Service charges must be a positive number')
        .required('Service charges are required'),
    minWithdraw: Yup.number()
        .typeError('Minimum withdrawal must be a number')
        .positive('Minimum withdrawal must be a positive number')
        .required('Minimum withdrawal is required'),
    minRentalDuration: Yup.number()
        .typeError('Minimum rental duration must be a number')
        .positive('Minimum rental duration must be a positive number')
        .required('Minimum rental duration is required'),
    GST: Yup.number()
        .typeError('GST must be a number')
        .positive('GST must be a positive number')
        .required('GST is required'),
    officeAddress: Yup.string().trim().required('Office address is required'),
    contactEmail: Yup.string()
        .trim()
        .email('Invalid email address')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ ,
            'Invalid email address.'
        )
        .required('Contact email is required'),
});

export default settingsValidation;
