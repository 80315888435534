import AddNewCategoryModel from 'components/blogCategories/AddNewCategoryModel';
import CategoriesTable from 'components/blogCategories/CategoriesTable';
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDocs, setDocsCount, setShowAddNewModel } from 'redux/reducers/blogCategoryReducer';
import fetcher from 'utils/fetcher';

const BlogCategories = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs , docsCount , showAddNewModel } = useSelector(state => state.blogCategory);

    const queryKey = ['fetch-all-blog-categories' ];
    const { isLoading , data }   = useQuery(queryKey , () => {
        return fetcher(`/blog-category` , user);
    } , {
        refetchOnWindowFocus : false
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docCount));
        }
    } ,[data]);


    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Blog Categories' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <button 
                    className="btn-primary py-2 5 px-6"
                    onClick={() => dispatch(setShowAddNewModel(true))}
                    >
                        <button>Add New Category</button>
                    </button>
                </div>
                <div className="mt-8">
                    {
                        isLoading 
                        ? 
                            <Loader /> 
                        : 
                        docs?.length > 0 
                        ? 
                            <div className='shadow-bg '>
                                <CategoriesTable />
                            </div>
                        : 
                            <ItemNotFound message='No Blog Category found.' />
                    }
                </div>

                {
                    showAddNewModel && <AddNewCategoryModel />
                }
            </div>
        </Layout>
    )
}

export default BlogCategories