import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout';
import React, { useEffect } from 'react';
import { useState  } from 'react';
import FileInput from 'components/global/FileInput';
import NameInput from 'components/global/NameInput';
import PasswordInput from 'components/global/PasswordInput';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { vendor_edit, vendor_getDetails } from 'redux/actions/vendorActions';
import { ClipLoader } from 'react-spinners';
import Loader from 'components/global/Loader';
import { Form, Formik } from 'formik';
import editUserValidation from 'validations/editUserValidation';
import FormControl from 'components/global/form/FormControl';
import { toast } from 'react-toastify';

const initState = {
    fullName : '' ,
    email : '' ,
    phone : '' ,
}

const EditVendor = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [vendorData , setVendorData] = useState(initState);
    const { docDetails , updateLoading  , loading} = useSelector(state => state.vendor)
    

    useEffect(() => {
        dispatch(vendor_getDetails(id));
    }, [id , dispatch]);


    useEffect(() => {
        if(docDetails) {
            const { fullName , email , phone } = docDetails
            setVendorData({
                fullName , email , phone 
            });
        }
    } , [docDetails]);

    
    const submitHandler = formData => {
        if(JSON.stringify(formData) === JSON.stringify(vendorData)) {
            return toast.error("You have not made any changes to save.")
        }
        const data = {...formData , phone : formData?.phone?.toString() }
        dispatch(vendor_edit(id , data , navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Edit Vendor' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    <Formik
                    initialValues={vendorData}
                    validationSchema={editUserValidation}
                    onSubmit={submitHandler}
                    enableReinitialize
                    >
                        {
                            formik => {
                                return (
                                    <Form
                                    className='shadow-bg p-4 mt-4 flex flex-col gap-4'
                                    >
                                        <div className='flex sm:flex-row flex-col gap-6'>
                                            <FormControl
                                            control='input' 
                                            label='Full Name'
                                            placeholder='Ex: John Doe'
                                            name='fullName'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input' 
                                            type='email' 
                                            label='Email'
                                            placeholder='Ex: example@gmail.com'
                                            name='email'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='flex sm:flex-row flex-col gap-6'>
                                            <FormControl
                                            control='input' 
                                            type='number' 
                                            label='Phone'
                                            placeholder='Ex: 91839249283'
                                            name='phone'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className='mt-4'>
                                            <button 
                                            type="submit" 
                                            className='btn-primary py-3 px-12'
                                            disabled={updateLoading || !formik.isValid}
                                            >
                                                {
                                                    updateLoading 
                                                    ? 
                                                        <ClipLoader size={20} color='white' />
                                                    :   
                                                        'Update'
                                                }   
                                            </button>
                                        </div>

                                    </Form>
                                )
                            }
                        }

                    </Formik>
                }
            </div>
        </Layout>
    )
}

export default EditVendor;