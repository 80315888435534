import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDocs, setDocsCount } from 'redux/reducers/insuranceReducer';
import fetcher from 'utils/fetcher';
import InsuranceTable from './insuranceTable';
import { Link } from 'react-router-dom';

const InsuranceManagement = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs , docsCount  } = useSelector(state => state.insurance);

    const queryKey = ['fetch-all-insurance' ];
    const { isLoading , data  }   = useQuery(queryKey , () => {
        return fetcher(`/insurance` , user);
    } , {
        refetchOnWindowFocus : false
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docCount));
        }
    } ,[data]);


    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Insurance Management' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <Link 
                    to='/insurance-management/add'
                    className="btn-primary py-2 5 px-6"
                    >
                        <button>Add Insurance Plan</button>
                    </Link>
                </div>
                <div className="mt-8">
                    {
                        isLoading 
                        ? 
                            <Loader /> 
                        : 
                        docs?.length > 0 
                        ? 
                            <div className='shadow-bg '>
                                <InsuranceTable />
                            </div>
                        : 
                            <ItemNotFound message='No insurance plan found.' />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default InsuranceManagement