import FormControl from 'components/global/form/FormControl';
import Axios from 'config/api';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import * as Yup from 'yup';

const initValues = { email : '' };

const validations = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ ,
            'Invalid email address.'
        ),
});

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [_data, setData] = useState({ email : '' });
    const [isValid, setIsValid] = useState(false);
    const [loading , setLoading] = useState(false)


    const handleSubmit = async (formData) => {
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post(`/user/forgot-password` , { ...formData });
            toast.success(message);
            localStorage.setItem('email' , formData.email);
            navigate('/verify-otp');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full min-h-screen bg-gradient">
            <div className='shadow-bg bg-pure p-4 sm:w-[600px] w-full'>
                <center>
                    <h1 className='text-2xl font-bold'>Forgot Password</h1>
                    <p className='text-sm text-grayText'>You will get an OTP in your email</p>
                </center>
                <Formik
                initialValues={initValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
                >   
                    {
                        (formik) => {
                            return (
                                <Form>
                                    <div className='flex items-center justify-center'>
                                        <FormControl
                                        control='input'
                                        label='Email'
                                        placeholder='example@gmail.com'
                                        name='email'
                                        formik={formik}
                                        />
                                    </div>
                                    <center className='mt-8'>
                                        <button 
                                        className="btn-primary py-2 sm:px-12 px-6"
                                        disabled={loading || !formik.isValid}
                                        type='submit'
                                        >
                                            {
                                                loading 
                                                ?
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Send Otp'
                                            }
                                        </button>
                                    </center>
                                </Form>
                            )
                        }
                    }

                </Formik>
                <div className='text-sm text-dark mt-4 text-center flex items-center justify-center gap-1'>
                    <button 
                    className=' underline'
                    onClick={() => navigate('/login')}
                    type='button'
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;