import Search from 'components/global/Search';
import React from 'react'
import { useDispatch } from 'react-redux';
import { setKeyword, setStatus } from 'redux/reducers/withdrawReducer';

const statuses = ['pending' , 'approved' , 'declined'];

const Filters = () => {
    const dispatch = useDispatch();

    const searchFetcher = value => {
        dispatch(setKeyword(value));
    }

    return (
        <div className='box-shadow gray-border p-4 pb-6 '>

            <div className="flex items-center gap-4 sm:flex-row flex-col">
                <div className='sm:w-[300px] w-full'>
                    <Search
                    label='username or phone '
                    fetcher={searchFetcher}
                    onClick={e => e.stopPropagation()}
                    />
                </div>
                <div className='sm:w-[300px] w-full'>
                    <div className='form-row'>
                        <select 
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={e => dispatch(setStatus(e.target.value))}
                        >
                            <option value="">All</option>
                            {
                                statuses.map(item => (
                                    <option key={item} value={item} className='capitalize'>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Filters