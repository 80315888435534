import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from 'redux/actions/authActions';
import { PulseLoader } from 'react-spinners';
import loginValidations from 'validations/loginValidation';
import { Form, Formik } from 'formik';
import FormControl from 'components/global/form/FormControl';

const initValues = {
    identifier : '' ,
    password : '' ,
}


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading } = useSelector(state => state.auth);

    const handleSubmit = (formData) => {
        dispatch(login(formData , navigate))
    }
 

    return (
        <div className='w-full min-h-screen fixed top-0 left-0  flex items-center justify-center bg-gradient'
        >
            <div className='flex rounded-md bg-pure sm:w-[600px] w-[95%]'>
                <div className='flex-1'>
                    <div className=' px-6 '>
                        <div className='text-center flex flex-col gap-3 text-sm text-grayText pt-6'>
                            <div className='flex items-center justify-center border-b pb-4 text-gradient text-2xl font-bold'>
                                Rent N Drive                       
                            </div>
                            <h3 className='text-3xl text-dark font-semibold uppercase'>Sign In</h3>
                            <p className='-translate-y-2'>Sign in to an Admin Account</p>
                        </div>
                        <Formik
                        initialValues={initValues}
                        validationSchema={loginValidations}
                        onSubmit={handleSubmit}
                        >
                            {
                                (formik) => {
                                    return (
                                        <Form
                                        className='flex flex-col gap-4 py-8'
                                        >
                                            <FormControl
                                            control='input'
                                            label='Email or Phone'
                                            placeholder='Enter your email or phone'
                                            name='identifier'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='password' 
                                            label='Password'
                                            placeholder='Ex : ******** '
                                            name='password'
                                            formik={formik}
                                            />
                                            <div className='flex items-center justify-end gap-2 text-dark underline text-sm'>
                                                <Link to='/forgot-password'>
                                                    Forgot Password
                                                </Link>
                                            </div>
                                            <div>
                                                <button 
                                                type='submit' 
                                                className='btn-primary py-2.5 px-12 w-full' 
                                                disabled={loading || !formik.isValid}
                                                >
                                                    {
                                                        loading 
                                                        ? 
                                                            <PulseLoader size={10} color='#fff' /> 
                                                        : 
                                                            'Sign In'
                                                    } 
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login