import NameInput from 'components/global/NameInput';
import React, { useRef, useState } from 'react'
import AvatarImg from 'assets/images/avatar.png';
import Heading from 'components/global/Heading';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'components/global/Input';
import isBase64 from 'utils/isBase64';
import { updateProfile } from 'redux/actions/authActions';
import { ClipLoader } from 'react-spinners';
import { baseURL } from 'config/api';
import * as Yup from 'yup';


const UpdateProfile = () => {
    const imgRef = useRef(null);
    const dispatch = useDispatch();
    const { user , updateLoading } = useSelector(state => state.auth);
    const [profileData , setProfileData] = useState({
        fullName : user?.fullName ,
    });
    const [image , setImage] = useState(baseURL + '/users/' + user?.image);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImage(reader.result);
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        const body = { ...profileData }
        if(isBase64(image)) {
            body.image = image;
        }
        dispatch(updateProfile(body))
    }

    return (
        <div>
            <Heading title='Profile' showIcon={false} />
            <div className='shadow-bg p-4 mt-4'>
                <form 
                className=' flex flex-col gap-4'
                onSubmit={handleSubmit}
                >
                    <div className='flex items-center justify-center flex-col mb-8'>
                        <div className='w-[120px] h-[120px] rounded-full border'>
                            <input 
                            type="file" 
                            hidden 
                            ref={imgRef} 
                            onChange={handleFileChange}
                            accept='image/.jpg,.jpeg,.png'
                            />
                            <img 
                            src={image} 
                            alt={'lsadk'}
                            className='w-full h-full rounded-full object-cover' 
                            />
                        </div>
                        <div className='mt-4'>
                            <button
                            type='button'
                            className="bg-primary rounded-full text-pure py-1.5 px-10"
                            onClick={() => imgRef.current.click() }
                            >
                                Profile Picture
                            </button>
                        </div>
                    </div>
                    <div className='flex items-center gap-4 sm:flex-row flex-col'>
                        <NameInput
                        label='Full Name'
                        placeholder='Your full name'
                        name='fullName'
                        data={profileData}
                        setData={setProfileData}
                        required
                        />
                        <Input
                        label='Email'
                        placeholder='Your Email'
                        value={user?.email}
                        readOnly
                        disabled
                        />
                    </div>
                    <div>
                        <Input
                        label='Phone'
                        placeholder='Your name'
                        value={user?.phone}
                        readOnly
                        disabled
                        />
                    </div>
                  
                    <div className='mt-4 flex items-end justify-end'>
                        <button 
                        className="btn-primary py-2 px-8"
                        disabled={updateLoading}
                        >
                            {
                                updateLoading
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Update'
                            }
                        </button>
                    </div>
                    <div className='text-red-500 mt-2 text-sm font-medium'>
                        <p>NOTE : You can't edit your email and phone</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdateProfile