import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name : 'notification' ,
    initialState : {
        docs : [] ,
        recentUnreadDocs : [] ,
        unreadNotificationsCount : 0 ,
        docDetails : null ,
        loading : false , 
        createLoading : false ,
        recentLoading : false ,
        markAsReadLoading : false ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 , 
    } , 
    reducers : {
        setStats (state , action) {
            const { docs , docsCount = 0 , page = 1 , pages = 1 } = action.payload;
            state.docs = docs;
            state.docsCount = docsCount;
            state.currentPage = page;
            state.pages = pages;
        } , 
        updateDoc (state , action) {
            console.log({ state , paylaod : action.payload})
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
        } ,
        setRecentUnreadDocs (state , action) {
            state.recentUnreadDocs = action.payload;
        } ,
        setUnreadNotificationCount (state , action) {
            state.unreadNotificationsCount = action.payload;
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setRecentLoading (state , action) {
            state.recentLoading = action.payload;
        } ,
        setMarkAsReadLoading (state , action) {
            state.markAsReadLoading = action.payload;
        } ,
        setCreateLoading (state , action) {
            state.createLoading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setKeyword (state , action) {
            state.keyword = action.payload;
        } , 
        setAllAsRead (state, action) {
            state.docs = state.docs.map(item => {
                item.status = 'read';
                return item;
            })
        }
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setCurrentPage , setCreateLoading , setStats , setRecentUnreadDocs , setUnreadNotificationCount , setRecentLoading , updateDoc , setMarkAsReadLoading , setAllAsRead
} = notificationSlice.actions;

export default notificationSlice.reducer;