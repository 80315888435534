import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout';
import React from 'react';
import { useState  } from 'react';
import FileInput from 'components/global/FileInput';
import NameInput from 'components/global/NameInput';
import PasswordInput from 'components/global/PasswordInput';
import { useDispatch, useSelector } from 'react-redux';
import { vendor_createDoc } from 'redux/actions/vendorActions';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import isBase64 from 'utils/isBase64';
import { Form, Formik } from 'formik';
import registerValidations from 'validations/registerValidation';
import FormControl from 'components/global/form/FormControl';

const initValues = {
    fullName : '' ,
    email : '' ,
    phone : '' ,
    password : '' ,
    image : ''
}


const AddVendor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { createLoading } = useSelector(state => state.vendor);

    const submitHandler = (formData) => {
        const data = {...formData , phone : formData?.phone?.toString()};
        delete data.image;
        if(isBase64(formData?.image)) {
            data.image = formData?.image;
        }
        dispatch(vendor_createDoc(data , navigate))
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Add New Vendor' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                <Formik
                initialValues={initValues}
                validationSchema={registerValidations}
                onSubmit={submitHandler}
                >
                    {
                        formik => {
                            return (
                                <Form
                                className='shadow-bg p-4 mt-4 flex flex-col gap-4'
                                >
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl 
                                        control='input'
                                        label='Full Name'
                                        placeholder='Ex: John Doe'
                                        name='fullName'
                                        formik={formik}
                                        />
                                        <FormControl 
                                        control='input'
                                        label='Email'
                                        placeholder='Ex: example@gmail.com'
                                        name='email'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-6'>
                                        <FormControl 
                                        control='input'    
                                        type='number'
                                        label='Phone'
                                        placeholder='Ex: 91839249283'
                                        name='phone'
                                        formik={formik}
                                        />
                                        <FormControl 
                                        control='password' 
                                        label='Password'
                                        placeholder='Ex: **********'
                                        name='password'
                                        formik={formik}
                                        />
                                    </div>
                                    <div>
                                        <FormControl
                                        control='file' 
                                        label='Image'
                                        name='image'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <button 
                                        type="submit" 
                                        className='btn-primary py-3 px-12'
                                        disabled={createLoading || !formik.isValid}
                                        >
                                            {
                                                createLoading
                                                ? 
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Create Vendor'
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </Layout>
    )
}

export default AddVendor;