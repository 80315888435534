import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay , Navigation } from 'swiper/modules';
import { memo } from 'react';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDocs, setDocsCount } from 'redux/reducers/blogCategoryReducer';
import fetcher from 'utils/fetcher';
import { baseURL } from 'config/api';
import Loader from 'components/global/Loader';
import { setCategory, setKeyword, setSort } from 'redux/reducers/blogReducer';

const Categories = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs  } = useSelector(state => state.blogCategory);
    const { category } = useSelector(state => state.blog);

    const queryKey = ['fetch-all-blog-categories' ];
    const { isLoading , data }   = useQuery(queryKey , () => {
        return fetcher(`/blog-category` , user);
    } , {
        refetchOnWindowFocus : false
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docCount));
        }
    } ,[data]);

    const categoryHandler = (item) => {
        dispatch(setCategory(item?._id))
    }

    const clearFilterHandler = () => {
        dispatch(setCategory(''));
        dispatch(setSort('latest'));
        dispatch(setKeyword(''));
    }

    return (
        <div className='mt-8'>
            <div className='flex items-center justify-between gap-4'>
                <h3 className="heading-sm">
                    Categories
                </h3>
                <div 
                className='text-primary underline cursor-pointer'
                onClick={clearFilterHandler}
                >
                    Clear Filters
                </div>
            </div>
            <div className="mt-2">
                <Swiper
                    slidesPerView={5}
                    spaceBetween={30}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        stopOnLastSlide: false,
                        waitForTransition: true ,
                        reverseDirection: true,
                        pauseOnMouseEnter : true
                    }}
                    navigation={true}  
                    modules={[Autoplay, Navigation]}
                    breakpoints={{
                        20: {
                            slidesPerView: 1.5,
                            spaceBetween: 10,
                        },
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        1550: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                        },
                    }}

                >
                    {
                        isLoading
                        ? 
                            <Loader />
                        : 
                        docs?.length > 0 
                        ?
                            docs?.map(item => (
                                <SwiperSlide key={item?._id}>
                                    <div 
                                    className={`relative  h-[150px] rounded-lg flex items-center justify-center text-pure cursor-pointer
                                    ${category === item?._id ? 'border-2 border-secondary' : ''}    
                                    `}
                                    style={{
                                        backgroundImage : `url(${baseURL + '/blogCategories/' + item?.image})` ,
                                        backgroundSize : 'cover', 
                                        backgroundRepeat : 'no-repeat'
                                    }}
                                    onClick={() => categoryHandler(item)}
                                    >
                                        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 rounded-lg"/>
                                        <p className='z-10'>
                                            {item?.name}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))
                        : 
                            ''
                    }

                </Swiper>
            </div>
        </div>
    )
}

export default Categories