import { Link } from 'react-router-dom';

const NotificationToast = ({ notification : { title , body , redirectUrl } }) => {
    return (
        <>
            <Link to={redirectUrl ? redirectUrl : '/notifications'} className='bg-white rounded-md'>
                <h2 className='text-lg font-semibold mb-4 text-primary'>
                    New Notification
                </h2>
                <h3 className='text-base font-medium'>
                    <b>{title}</b>
                </h3>
                <p className='text-grayText text-sm'>{body}</p>
            </Link>
        </>
    )
}

export default NotificationToast;