import PopupLayout from 'components/global/PopupLayout'
import Axios from 'config/api';
import moment from 'moment';
import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { setDocDetails } from 'redux/reducers/carReducer';
import toastError from 'utils/toastError';

const AddUnAvailabilityPopup = ({ setShowPopup }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docDetails : carDetails  } = useSelector(state => state.car);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [loading , setLoading] = useState(false);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const addUnavailabilityDatesHandler = async () => {
        setLoading(true);
        const body = { startDate , endDate };
        try {
            const { data : { data : { doc , message } } } = await Axios.post(`car/add-unavailability-dates/${id}` , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            dispatch(setDocDetails(doc));
            setLoading(false);
            setShowPopup(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }


    const existingUnavailableDates = carDetails?.unavailableDates?.map(({ startDate, endDate }) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return { start, end };
    });

    return (
        <PopupLayout
        setShowPopup={setShowPopup}
        >
            <div>
                <center>
                    <h4 className="sm:text-2xl font-medium text-xl text-primary">
                        Add Unavailability
                    </h4>
                </center>


                <div className='mt-4 flex md:flex-row flex-col items-center gap-4'>
                    <ReactDatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    minDate={new Date()}
                    inline
                    excludeDateIntervals={existingUnavailableDates}
                    />
                    <div className='flex-1 flex flex-col gap-4'>
                        <div className='shadow-bg p-3 flex items-center justify-between'>
                            <p>Start Date:</p>
                            <p>
                                {
                                    startDate 
                                    ? 
                                        moment(startDate).format('DD MMM YYYY')
                                    : 
                                        'Not Selected'
                                }
                            </p>
                        </div>
                        <div className='shadow-bg p-3 flex items-center justify-between'>
                            <p>End Date:</p>
                            <p>
                                {
                                    endDate 
                                    ? 
                                    moment(endDate).format('DD MMM YYYY')
                                    : 
                                        'Not Selected Yet'
                                }
                            </p>
                        </div>
                        {
                            startDate && endDate 
                            ? 
                                <div className='flex items-end justify-end'>
                                    <button 
                                    className="btn-primary py-2 px-4"
                                    onClick={addUnavailabilityDatesHandler}
                                    disabled={loading}
                                    >
                                        {
                                            loading 
                                            ? 
                                                <ClipLoader size={20} color='white' />
                                            : 
                                                'Submit'
                                        }
                                    </button>
                                </div>
                            : 
                                ''
                        }
                    </div>
                </div>
                <div>

                </div>
            </div>
        </PopupLayout>
    )
}

export default AddUnAvailabilityPopup