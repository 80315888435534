import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './App.css';
import './utilities.css';
import App from './App';
import DrawerContextProvider from 'context/DrawerContext';


import "react-datepicker/dist/react-datepicker.css";
import "react-accordion-comp/dist/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';



import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Provider } from 'react-redux';
import store from 'redux/store';

// React Query
import { QueryClient, QueryClientProvider } from'react-query';
import { SocketProvider } from 'context/socketContext';
const queryClient = new QueryClient();


if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
        .then(function (registration) {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(function (error) {
            console.error('Service Worker registration failed:', error);
        });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <QueryClientProvider client={queryClient}>
            <Provider store={store} >
                <SocketProvider>
                    <DrawerContextProvider>
                        <App />
                    </DrawerContextProvider>
                </SocketProvider>
            </Provider>
        </QueryClientProvider>
    </Router>
);

