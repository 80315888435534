import * as Yup from 'yup';

const couponValidation = Yup.object().shape({
    code: Yup.string().required('Coupon code is required.'),
    couponType: Yup.string().oneOf(['firstBooking', 'default']).required('Coupon type is required.'),
    discountType: Yup.string().oneOf(['amount', 'percentage']).required('Discount type is required.'),
    discount: Yup.number().min(0).required('Discount is required.'),
    minBookingAmount: Yup.number().min(0).required('Minimum booking amount is required.'),
    isActive: Yup.boolean().required('Status is required'),
    startDate: Yup.date().required('Start date is required.'),
    endDate: Yup.date().required().min(Yup.ref('startDate')).required('End date is required.'),
    maxUseCount: Yup.number().integer().min(1).required('Max use count is required.'),
});

export default couponValidation;
