import React, { memo, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { vendor_block, vendor_delete, vendor_edit, vendor_enableDisable } from 'redux/actions/vendorActions';
import { user_block } from 'redux/actions/userActions';
import RequestStatus from 'components/global/RequestStatus';
import confirmBox from 'utils/confirmBox';

const VendorsTable = ({ selectedVendors , setSelectedVendors , selectAll , setSelectAll , setFetch }) => {
    const dispatch = useDispatch();
    const dropMenuRef = useRef(null);
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    
    const { docs : sellers , blockLoading , deleteLoading } = useSelector(state => state.vendor);
  
    const selectUserHandler = (event, userId) => {
        if (event.target.checked) {
            setSelectedVendors([...selectedVendors, userId]);
        } else {
            setSelectedVendors(selectedVendors.filter(id => id !== userId));
        }
    };
  
    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        setSelectedVendors(event.target.checked ? sellers?.map(seller => seller?._id) : []);
    };
    
    useClickOutside(dropMenuRef , () => setShowDropMenu(false))

    const blockHandler = async (id , status) => {
        const message = `Are you sure? You want to ${status === 'active' ? 'unblock' : 'block'} this vendor?`;
        const onYesClick = async () => {
            await dispatch(vendor_edit(id, { status }))
            setShowDropMenu(false);
        } 

        confirmBox({ message , onYesClick })
    }

    const deleteHandler = async (id , status ) => {
        setFetch(false);
        console.log({ status })
        const message = `Are you sure? You want to ${status === 'active' || status === 'inActive' ? 'activate' : 'delete'} this vendor?`;
        const onYesClick = async () => {
            await dispatch(vendor_edit(id , { status }));
            setShowDropMenu(false);
            setFetch(true);
        }
        confirmBox({ message , onYesClick })
    }

    return (
        <div className="">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            <div className='flex items-center gap-2'>
                                <input 
                                type="checkbox" 
                                id='select-all' 
                                onChange={handleSelectAll}
                                checked={selectAll}
                                />
                                <label htmlFor="select-all" >
                                    Select All
                                </label>
                            </div>
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Full Name
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Email
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Phone Number
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Status
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                   {
                        sellers?.map((item , i) => (
                            <tr
                            key={item._id} 
                            className="bg-white border-b transition duration-300 ease-in-out"
                            >
                            <td className=" text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <div className='flex items-center gap-2'>
                                    <input 
                                    id={item._id}
                                    type="checkbox" 
                                    checked={item?.status !== 'deleted' && selectedVendors.includes(item._id)}
                                    onChange={(e) => selectUserHandler(e , item._id)}
                                    disabled={item?.status === 'deleted'}
                                    />
                                    <label htmlFor={item?._id}>
                                        <i 
                                        className="uil uil-mobile-android-alt text-base"
                                        ></i>
                                    </label>
                                </div>                                
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                <Link 
                                to={`/vendor-management/vendors/${item._id}`}
                                className='text-primary underline'
                                >
                                    {item?.fullName}
                                </Link>
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {item?.email}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {item?.phone}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                <RequestStatus status={item?.status} />
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                    {/* DROP MENU */}
                                    {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                            {
                                                item?.status === 'active' || item?.status === 'inActive'
                                                ?
                                                    <Link 
                                                    to={`/vendor-management/edit-vendor/${item._id}`} 
                                                    className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'>
                                                        <span>Edit </span>
                                                    </Link>
                                                : 
                                                    ''
                                            }

                                            {
                                                item?.status !== 'deleted' && 
                                                <button 
                                                className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'
                                                onClick={() => {
                                                    blockHandler(
                                                        item?._id , 
                                                        item?.status === 'blocked' ? item?.kyc ? 'active' : 'inActive' : 'blocked'
                                                    )
                                                }}
                                                disabled={blockLoading}
                                                >
                                                    {
                                                        blockLoading
                                                        ? 
                                                            <ClipLoader size={15} />
                                                        : 
                                                        <span>
                                                            {
                                                                item?.status === 'blocked'
                                                                ? 
                                                                    'Unblock User'
                                                                : 
                                                                    'Block User'
                                                            }
                                                        </span>
                                                    }
                                                </button>
                                            }
                                            <div
                                            onClick={() => deleteHandler(
                                                item?._id , 
                                                item.status === 'deleted' ? item?.kyc ? 'active' : 'inActive' : 'deleted'
                                            )} 
                                            className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                            disabled={deleteLoading}
                                            >
                                                {
                                                    deleteLoading 
                                                    ? 
                                                        <ClipLoader size={15} />
                                                    :   
                                                    <span>
                                                    {
                                                        item.status === 'deleted'
                                                        ? 
                                                            'Undo Delete'
                                                        : 
                                                            'Delete'
                                                    }
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
           
        </div>
    )
}

export default memo(VendorsTable);