import './styles.css';
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/authActions';
import { ClipLoader } from 'react-spinners';

const Sidebar = () => {
    const { loading } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarRef = useRef();
    const location = useLocation();
    const { showDrawer , setShowDrawer } = useDrawerContext();

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

   const logoutHandler = () => {
        dispatch(logout(navigate));
   }

    return (
        <div className=''>
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0 md:left-0 -left-[200%] w-[270px]  overflow-auto py-4 z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='overflow-auto'>
                    <div className='flex items-center justify-center border-b pb-4 text-gradient text-2xl font-bold'>
                        <Link to='/'>
                            Rent&Drive                       
                        </Link>
                    </div>
                    <ul className='sideMenu-list mt-6 text-dark min-h-screen h-full'>
                        <li 
                        className={`${isActive('' , true) ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/'>   
                                <i className="uil uil-apps"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('user-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/user-management'>   
                                <i className="uil uil-user"></i>
                                <span>User Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('vendor-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/vendor-management'>   
                                <i className="uil uil-clipboard-notes"></i>
                                <span>Vendor Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('booking-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/booking-management' >
                                <i className="uil uil-shopping-cart"></i>
                                <span>Booking Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('cars-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/cars-management'>   
                                <i className="uil uil-chart-pie-alt"></i>
                                <span>Cars Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('kyc') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/kyc'>   
                                <i className="uil uil-file-check"></i>
                                <span>KYC Requests</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('withdraw-requests') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/withdraw-requests'>   
                                <i className="uil uil-usd-circle"></i>
                                <span>Withdraw Requests</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('insurance-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/insurance-management'>   
                                <i className="uil uil-shield-plus text-xl"></i>
                                <span>Insurance Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('blogs') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/blogs'>   
                                <i className="uil uil-document-layout-left"></i>
                                <span>Blogs</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('blog-categories') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/blog-categories'>   
                                <i className="uil uil-channel"></i>
                                <span>Blog Categories</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('slugs') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/slugs'>   
                                <i className="uil uil-tag-alt"></i>
                                <span>Slug Management</span>
                            </Link>
                        </li>

                        <li 
                        className={`${isActive('coupons') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/coupons'>   
                                <i className="uil uil-award-alt"></i>
                                <span>Discount Coupons</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('send-notification') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/send-notification'>   
                                <i className="uil uil-bell"></i>
                                <span>Send Notification</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('settings') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/settings'>   
                                <i className="uil uil-setting"></i>
                                <span>Settings</span>
                            </Link>
                        </li>
                        <li 
                        className={`sideMenu-item signout`}
                        onClick={logoutHandler}
                        >
                            {
                                loading
                                ? 
                                    <ClipLoader size={20} color='black' />
                                : 
                                    <>
                                        <i className="uil uil-signout"></i>
                                        <span>Sign Out</span>
                                    </>
                            }
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    )
}

export default Sidebar