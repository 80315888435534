import BackBtn from 'components/global/BackBtn'
import FormControl from 'components/global/form/FormControl';
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import { Form, Formik  } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { slug_getDetails , slug_edit } from 'redux/actions/slugActions';
import * as Yup from 'yup';

const slugValidations = Yup.object().shape({
    slug: Yup.string()
        .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'slug can only contain lowercase letters, numbers, and hyphens, with no leading or trailing hyphens')
        .max(100, 'slug cannot be more than 100 characters')
        .trim()
        .required('slug is required'),
});


const initValues = {
    slug : '' ,
    metaTags: [] ,
    pageTitle : ''
}

const EditSlug = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateLoading , docDetails } = useSelector(state => state.slug);

    const initValues = {
        slug : docDetails?.slug || '',
        pageTitle : docDetails?.pageTitle || '',
        metaTags: docDetails?.metaTags || [] ,
    }


    useEffect(() => {
        if(!docDetails) {
            dispatch(slug_getDetails(slug));
        }
    } , [docDetails , dispatch , slug]);

    const handleSubmit = (formData) => {
        const body = {...formData};
        dispatch(slug_edit(docDetails?._id , body , navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Update Slug' />
                    </div>
                    <BackBtn />
                </div>
                <div className="mt-4 shadow-bg px-4">
                    <Formik
                        initialValues={initValues}
                        validationSchema={slugValidations}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {
                            (formik) => {
                                return (
                                    <Form
                                        className='py-8 '
                                    >
                                        <div className='flex flex-col gap-4 '>
                                            <div className='flex gap-4 lg:flex-row flex-col'>
                                                <FormControl
                                                    control='input'
                                                    label='Slug'
                                                    placeholder='Enter slug'
                                                    name='slug'
                                                    formik={formik}
                                                />
                                                <FormControl
                                                    control='input'
                                                    label='Page Title'
                                                    placeholder='Enter page title'
                                                    name='pageTitle'
                                                    formik={formik}
                                                />

                                            </div>
                                            <FormControl
                                                control='meta'
                                                label='Meta Tags'
                                                name='metaTags'
                                                formik={formik}
                                            />
                                        </div>
                                        <div className='mt-8'>
                                            <button 
                                            className="btn-primary py-2 px-10"
                                            disabled={updateLoading || !formik.isValid}
                                            >
                                                {
                                                    updateLoading 
                                                    ? 
                                                        <ClipLoader size={20} />
                                                    : 
                                                        'Update'
                                                }
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                    
                </div>
            </div>
        </Layout>
    )
}

export default EditSlug