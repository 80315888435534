import PopupLayout from 'components/global/PopupLayout'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowAddNewModel } from 'redux/reducers/blogCategoryReducer';
import { PulseLoader } from 'react-spinners';
import { Form, Formik } from 'formik';
import blogCategoryValidations from 'validations/blogCategoryValidations';
import FormControl from 'components/global/form/FormControl';
import { blogCategory_createDoc } from 'redux/actions/blogCategoryActions';

const initValues = {
    name : '' ,
    image : ''
}

const AddNewCategoryModel = () => {
    const dispatch = useDispatch();
    const { showAddNewModel , createLoading } = useSelector(state => state.blogCategory);

    const handleSubmit = (formData) => {
        dispatch(blogCategory_createDoc(formData));
    }

    return (
        <PopupLayout
            setShowPopup={setShowAddNewModel}
            redux
        >
            <center>
                <h3 className='text-xl font-semibold text-gradient'>Add New Category</h3>
            </center>
            <Formik
                initialValues={initValues}
                validationSchema={blogCategoryValidations}
                onSubmit={handleSubmit}
            >
                {
                    (formik) => {
                        return (
                            <Form
                                className='flex flex-col gap-4 mt-8'
                            >
                                <FormControl
                                    control='input'
                                    label='Name'
                                    placeholder='Enter category name'
                                    name='name'
                                    formik={formik}
                                />
                                <FormControl
                                    control='file'
                                    label='Image'
                                    name='image'
                                    formik={formik}
                                />
                                <div className='mt-10'>
                                    <button
                                        type='submit'
                                        className='btn-primary py-2.5 px-12'
                                        disabled={createLoading || !formik.isValid}
                                    >
                                        {
                                            createLoading
                                            ?
                                                <PulseLoader size={10} color='#fff' />
                                            :
                                                'Submit'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </PopupLayout>
    )
}

export default AddNewCategoryModel