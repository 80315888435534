import isDateInRange from "./isDateInRange";

const filterBookingStatuses = (currentStatus, pickupDate, returnDate) => {
    let statuses = [];
    switch (currentStatus) {
        case 'pending':
            statuses = ['accepted', 'declined']; 
            return statuses;
        case 'accepted':
            statuses = ['cancelled' , 'picked' ]; 
            return statuses;
        case 'picked':
            return ['completed']
        default:
            return [];
    }
};
  
  
export default filterBookingStatuses;