import Editor from 'components/blogs/Editor';
import BackBtn from 'components/global/BackBtn'
import FormControl from 'components/global/form/FormControl';
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader';
import { baseURL } from 'config/api';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { blog_edit, blog_getDetails } from 'redux/actions/blogActions';
import { blogCategory_getAll } from 'redux/actions/blogCategoryActions';
import blogValidations from 'validations/blogValidations';

// title , content , image , meta tags , tags , slug  , author

const imgDir = '/blogs/'

const EditBlog = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading: blogCategoryLoading, docs: blogCategories } = useSelector(state => state.blogCategory)
    const { updateLoading, docDetails, loading } = useSelector(state => state.blog);
    const [content, setContent] = useState(docDetails?.content || '');


    const initValues = {
        title: docDetails?.title || '',
        slug: docDetails?.slug || '',
        image: docDetails?.image ? baseURL + imgDir + docDetails?.image : '',
        metaTags: docDetails?.metaTags || [{ name: '', content: '' }],
        tags: docDetails?.tags || [],
        category: docDetails?.category?._id || '',
        published: docDetails?.published,
        shortDescription: docDetails?.shortDescription || ''
    }

    useEffect(() => {
        dispatch(blogCategory_getAll());
    }, []);

    useEffect(() => {
        if (!docDetails) {
            dispatch(blog_getDetails(id));
        }
    }, [docDetails, dispatch, id]);

    useEffect(() => {
        if (docDetails) {
            setContent(docDetails.content || '');
        }
    }, [docDetails]);


    const handleSubmit = (formData) => {
        if (!content || content?.length < 10) {
            return toast.error('Content must be at least 10 characters long.');
        }
        const body = { ...formData, content };
        if (body?.image === baseURL + imgDir + docDetails?.image) {
            delete body?.image;
        }
        dispatch(blog_edit(id, body, navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Edit Blog' icon='pen' />
                    </div>
                    <BackBtn />
                </div>
                {
                    loading
                        ?
                        <Loader />
                        :
                        <div className="mt-4 shadow-bg px-4">
                            <Formik
                                initialValues={initValues}
                                validationSchema={blogValidations}
                                onSubmit={handleSubmit}
                                enableReinitialize
                            >
                                {
                                    (formik) => {
                                        return (
                                            <Form
                                                className='py-8 flex lg:flex-row flex-col gap-10'
                                            >
                                                <div className='lg:flex-[0.75] flex-1 flex flex-col gap-4 '>
                                                    <div className='flex gap-4 lg:flex-row flex-col'>
                                                        <FormControl
                                                            control='input'
                                                            label='Title'
                                                            placeholder='Enter blog title'
                                                            name='title'
                                                            formik={formik}
                                                        />
                                                        <FormControl
                                                            control='input'
                                                            label='Slug'
                                                            placeholder='Enter slug'
                                                            name='slug'
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    <Editor
                                                        content={content}
                                                        setContent={setContent}
                                                    />
                                                    <div className="mt-10">
                                                        {
                                                            blogCategoryLoading
                                                                ?
                                                                <ClipLoader size={20} />
                                                                :
                                                                blogCategories?.length > 0
                                                                    ?
                                                                    <FormControl
                                                                        control='select'
                                                                        label='Category'
                                                                        placeholder='Select category'
                                                                        name='category'
                                                                        formik={formik}
                                                                        options={blogCategories?.map(item => (
                                                                            { key: item?.name, value: item?._id }
                                                                        ))}
                                                                        limit={3}
                                                                    />
                                                                    :
                                                                    <div className='flex flex-col gap-1'>
                                                                        <p className='text-sm text-red-500'>Blog Category not found.</p>
                                                                        <Link
                                                                            to='/blog-categories'
                                                                            className="btn-primary py-2 px-4"
                                                                            type='button'
                                                                        >
                                                                            Add Category
                                                                        </Link>
                                                                    </div>
                                                        }
                                                    </div>

                                                    <FormControl
                                                        control='tags'
                                                        label='Tags'
                                                        placeholder='Enter tags for blog'
                                                        name='tags'
                                                        formik={formik}
                                                        limit={3}
                                                    />
                                                    <FormControl
                                                        control='meta'
                                                        label='Meta Tags'
                                                        name='metaTags'
                                                        formik={formik}
                                                    />
                                                </div>
                                                <div className='lg:flex-[0.25] flex-1 flex flex-col justify-between'>
                                                    <div className='flex flex-col gap-4'>
                                                        <FormControl
                                                            control='file'
                                                            label='Cover Image'
                                                            name='image'
                                                            formik={formik}
                                                        />
                                                        <FormControl
                                                            control='textarea'
                                                            label='Short Description'
                                                            name='shortDescription'
                                                            maxLength={60}
                                                            formik={formik}
                                                        />
                                                        <FormControl
                                                            control='toggle'
                                                            label='Publish'
                                                            name='published'
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    <div className='flex items-end justify-end'>
                                                        <button
                                                            className="btn-primary py-2 px-10"
                                                            disabled={updateLoading || !formik.isValid}
                                                        >
                                                            {
                                                                updateLoading
                                                                    ?
                                                                    <ClipLoader size={20} />
                                                                    :
                                                                    'Update'
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>

                        </div>
                }
            </div>
        </Layout>
    )
}

export default EditBlog