import Cards from 'components/dashboard/Cards';
import TopCustomers from 'components/dashboard/TopCustomers';
import TopSellings from 'components/dashboard/TopSellings';
import TotalRevenue from 'components/dashboard/TotalRevenue';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { useSocket } from 'context/socketContext';
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setUser } from 'redux/reducers/authReducer';
import { setUnreadNotificationCount } from 'redux/reducers/notificationReducer';
import fetcher from 'utils/fetcher';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const [filter , setFilter] = useState('');
    const [stats , setStats] = useState('');
    const [topCustomers , setTopCustomers] = useState([]);
    const [topCars , setTopCars] = useState([]);

    const { isLoading , data } = useQuery('fetch-dashboard-details' , () => {
        return fetcher(`/user/admin-dashboard/details` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { totalBookings , totalCars , totalSellers , totalUsers , topCars , topCustomers , user : _user , unreadNotificationsCount } } } = data;
            setStats({
                totalBookings , totalCars , totalSellers , totalUsers
            });
            setTopCars(topCars);
            setTopCustomers(topCustomers);
            const updatedUser = { ..._user , token : user?.token };
            dispatch(setUser(updatedUser));
            localStorage.setItem('user' , 
                JSON.stringify(updatedUser)
            );

            dispatch(setUnreadNotificationCount(unreadNotificationsCount));
        }
    }, [data]);

   
    return (
        <Layout>
            {
                isLoading 
                ?   
                    <Loader />
                : 
                <div className='flex flex-col gap-8'>
                    <div>
                        <Cards 
                        stats={stats}
                        />
                    </div>
                    {/* <div>
                        <TotalRevenue />
                    </div> */}
                    {/* <div>
                        <OrdersList />
                    </div> */}
                    <div>
                        {
                            topCars?.length > 0 
                            ? 
                                <TopSellings 
                                topCars={topCars}
                                />
                            : 
                                <ItemNotFound message='No top sellings found.' />
                        }
                    </div>
                    <div>
                        {
                            topCustomers?.length > 0 
                            ? 
                                <TopCustomers 
                                topCustomers={topCustomers}
                                />
                            : 
                                <ItemNotFound message='No top customer found.' />
                        }
                    </div>
                    {/* <div>
                        <TopVendors />
                    </div> */}
                </div>
            }
        </Layout>
    )
}

export default Dashboard