import Editor from 'components/blogs/Editor';
import BackBtn from 'components/global/BackBtn'
import FormControl from 'components/global/form/FormControl';
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { blog_createDoc } from 'redux/actions/blogActions';
import { blogCategory_getAll } from 'redux/actions/blogCategoryActions';
import blogValidations from 'validations/blogValidations';

// title , content , image , meta tags , tags , slug  , author

const initValues = {
    title : '' ,
    image : '' ,
    metaTags: [] ,
    tags : [] ,
    category : '' , 
    published : true ,
    shortDescription : ''
}

const AddNewBlog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [content, setContent] = useState('');
    const { loading : blogCategoryLoading , docs : blogCategories } = useSelector(state => state.blogCategory)
    const { createLoading } = useSelector(state => state.blog);

    useEffect(() => {
        dispatch(blogCategory_getAll());
    } , []);


    const handleSubmit = (formData) => {
        if(!content || content?.length < 10) {
            return toast.error('Content must be at least 10 characters long.');
        }
        const body = {...formData , content};
        dispatch(blog_createDoc(body , navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Create New Blog' />
                    </div>
                    <BackBtn />
                </div>
                <div className="mt-4 shadow-bg px-4">
                    <Formik
                        initialValues={initValues}
                        validationSchema={blogValidations}
                        onSubmit={handleSubmit}
                    >
                        {
                            (formik) => {
                                console.log({ formik })
                                return (
                                    <Form
                                        className='py-8 flex lg:flex-row flex-col gap-10'
                                    >
                                        <div className='lg:flex-[0.75] flex-1 flex flex-col gap-4 '>
                                            <div className='flex gap-4 lg:flex-row flex-col'>
                                                <FormControl
                                                    control='input'
                                                    label='Title'
                                                    placeholder='Enter blog title'
                                                    name='title'
                                                    formik={formik}
                                                />
                                                <FormControl
                                                    control='input'
                                                    label='Slug'
                                                    placeholder='Enter slug'
                                                    name='slug'
                                                    formik={formik}
                                                />

                                            </div>
                                            <Editor
                                                content={content}
                                                setContent={setContent}
                                            />
                                            <div className="mt-10">
                                                {
                                                    blogCategoryLoading
                                                    ? 
                                                        <ClipLoader size={20} />
                                                    :
                                                    blogCategories?.length > 0 
                                                    ?
                                                        <FormControl
                                                            control='select'
                                                            label='Category'
                                                            placeholder='Select category'
                                                            name='category'
                                                            formik={formik}
                                                            options={blogCategories?.map(item => (
                                                                { key : item?.name , value : item?._id }
                                                            ))}
                                                            limit={3}
                                                        />
                                                    : 
                                                        <div className='flex flex-col gap-1'>
                                                            <p className='text-sm text-red-500'>Blog Category not found.</p>
                                                            <Link 
                                                            to='/blog-categories' 
                                                            className="btn-primary py-2 px-4" 
                                                            type='button'
                                                            >
                                                                Add Category
                                                            </Link>
                                                        </div>
                                                }
                                            </div>
                                           
                                            <FormControl
                                                control='tags'
                                                label='Tags'
                                                placeholder='Enter tags for blog'
                                                name='tags'
                                                formik={formik}
                                                limit={3}
                                            />
                                            <FormControl
                                                control='meta'
                                                label='Meta Tags'
                                                name='metaTags'
                                                formik={formik}
                                            />
                                        </div>
                                        <div className='lg:flex-[0.25] flex-1 flex flex-col justify-between'>
                                           <div className='flex flex-col gap-4'>
                                                <FormControl
                                                    control='file'
                                                    label='Cover Image'
                                                    name='image'
                                                    formik={formik}
                                                />
                                                <FormControl
                                                    control='textarea'
                                                    label='Short Description'
                                                    name='shortDescription'
                                                    maxLength={60}
                                                    formik={formik}
                                                />
                                                <FormControl
                                                    control='toggle'
                                                    label='Publish'
                                                    name='published'
                                                    formik={formik}
                                                />

                                           </div>
                                            <div className='flex items-end justify-end'>
                                                <button 
                                                className="btn-primary py-2 px-10"
                                                disabled={createLoading || !formik.isValid}
                                                >
                                                    {
                                                        createLoading 
                                                        ? 
                                                            <ClipLoader size={20} />
                                                        : 
                                                            'Submit'
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                    
                </div>
            </div>
        </Layout>
    )
}

export default AddNewBlog