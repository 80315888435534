import Search from 'components/global/Search'
import SelectBox from 'components/global/SelectBox'
import React from 'react'
import { useDispatch } from 'react-redux';
import { setKeyword, setSort } from 'redux/reducers/blogReducer';

const options = [
    {  label : 'Latest' , value : 'latest' } ,  
    {  label : 'Oldest' , value : 'oldest' } ,  
];

const Filter = () => {
    const dispatch = useDispatch();
    const searchFetcher = (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <div className='flex items-center sm:gap-8 gap-4 sm:flex-row flex-col'>
            <Search
            fetcher={searchFetcher}
            />
            <select 
            className='select-box'
            onChange={e => {
                dispatch(setSort(e.target.value))
            }}
            >
                {
                    options?.map(item => (
                        <option value={item?.value}>
                            {item?.label}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}

export default Filter