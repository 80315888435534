import Axios from 'config/api';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Timeago from 'react-timeago';
import { toast } from 'react-toastify';
import { updateDoc } from 'redux/reducers/notificationReducer';
import toastError from 'utils/toastError';

const NotificationItem = ({ item }) => {
    const dispatch = useDispatch();
    const [selectedItemId , setSelectedItemId] = useState(null);
    const [loading , setLoading] = useState(false);

    const { user } = useSelector(state => state.auth);


    const markAsReadHandler = async (item) => {
        setSelectedItemId(item?._id);
        setLoading(true);
        try {
            const { data : { data : { message , doc } } } = await Axios.get(`/notification/mark-single-as-read/${item?._id}` , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            console.log({ doc })
            dispatch(updateDoc(doc));
            toast.success(message);
            setLoading(false);
        } catch (error) { 
            setLoading(false);
            console.log(error)
            toastError(error);
        }
    }

    return (
        <div
            className='shadow-bg rounded-md p-4 hover:border-primary'
        >

            <div className='flex items-center justify-between'>
                <Link
                    to={`${item?.redirectUrl}`}
                >
                    <h6 className='font-medium flex items-center gap-2'>
                        <span>{item?.title}</span>
                        {
                            item?.status === 'unread' && (
                                <span className='bg-green-500 text-pure px-1.5 py-0.5 rounded-md text-xs'>
                                    new
                                </span>
                            )
                        }
                    </h6>
                </Link>
                <button 
                className='text-sm underline text-primary'
                disabled={loading}
                onClick={e => {
                    e.stopPropagation();
                    markAsReadHandler(item);
                }}
                >
                    {
                        selectedItemId === item?._id && loading 
                        ? 
                            <ClipLoader size={20} />
                        : 
                            'Mark as read'
                    }
                </button>
            </div>
            <div className='mt-2'>
                <Link
                    to={`${item?.redirectUrl}`}
                >
                    <p className='text-sm text-gray-500'>
                        {item?.description}
                    </p>
                </Link>
                <p className='mt-2 text-xs text-primary'>
                    <Timeago date={item?.createdAt} />
                </p>
            </div>
        </div>
    )
}

export default NotificationItem