import BackBtn from 'components/global/BackBtn';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import fetcher from 'utils/fetcher';
import KycInfo from 'components/userManagement/KycDetails';

const KycDetails = () => {
    const { id } = useParams();
    const [kyc , setKyc] = useState(null);
    const { user } = useSelector(state => state.auth)

    const queryKey = ['fetch-single-kyc-details' , id]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/kyc/${id}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            setKyc(doc);
        }
    } , [data]);

    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between gap-4 sm:flex-row flex-col mb-4">
                    <BackBtn />
                </div>
                {
                    isLoading
                    ? 
                        <Loader />
                    : 
                    kyc
                    ? 
                        <KycInfo kycData={kyc} />
                    : 
                        <ItemNotFound message='Booking not found.' />
                }
            </div>
        </Layout>
    )
}

export default KycDetails