import React, { memo, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import useClickOutside from 'utils/clickOutside';
import TimeAgo from 'react-timeago'
import { useDispatch, useSelector } from 'react-redux';
import { notification_getRecentUnread } from 'redux/actions/notificationActions';
import { ClipLoader } from 'react-spinners';

const arr = [1,2,3,4,5]
const Notifications = () => {
    const dispatch = useDispatch();
    const notificationsRef = useRef();
    const [show , setShow] = useState(false);

    const { recentUnreadDocs , unreadNotificationsCount , recentLoading } = useSelector(state => state.notification)

    useClickOutside(notificationsRef , () => setShow(false));

    useEffect(() => {
        if(show) {
            dispatch(notification_getRecentUnread());
        }
    } , [show])


    return (
        <div className='relative bg-stroke rounded-lg w-[35px] h-[35px] flex items-center justify-center'>
            <button
            onClick={() => setShow(true)}
            className='bg-darkSlate rounded-full w-[35px] h-[35px] flex items-center justify-center text-xl cursor-pointer border p-0.5'
            >
                <i className="uil uil-bell"></i>
            </button>
            <div className='absolute -top-2 -right-2 bg-red-500 w-5 h-5 rounded-full flex items-center justify-center text-pure text-xs'>
                {unreadNotificationsCount < 99 ? unreadNotificationsCount : '99+'}
            </div>

            <div
                ref={notificationsRef}
                className={`absolute top-[110%] sm:right-0 -right-20 w-[300px] sm:h-auto h-[400px] bg-pure border border-gray-400 rounded-lg overflow-auto z-[99999]
                transition-all duration-300 ease-in-out transform ${
                    show ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-10px] pointer-events-none'
                }`}
            >
                <div className='flex items-center justify-between p-4 border-b border-b-gray-300'>
                    <h6 className='font-semibold text-lg'>Notifications</h6>
                    <Link to='/notifications' className='underline text-sm hover:text-primary'>
                        View All
                    </Link>
                </div>
                {
                    recentLoading 
                    ? 
                        <div className='flex items-center justify-center h-[100px]'>
                            <ClipLoader size={20} />
                        </div>
                    : 
                        <div className='pb-2'>
                            {
                                recentUnreadDocs?.map((item,index) => (
                                    <div 
                                    key={item?._id} 
                                    className={`
                                        px-4 py-2 hover:bg-gray-200 duration-200 ease-in-out group cursor-pointer
                                        ${index !== (recentUnreadDocs?.length - 1) ? 'border-b border-b-gray-300' : ''}    
                                    `}>
                                        <Link
                                        to={item?.redirectUrl}
                                        >
                                            <div className="flex items-center justify-between">
                                                <h6 className='font-medium text-sm'>
                                                    <span>
                                                        {item?.title}    
                                                    </span> 
                                                </h6>
                                                {
                                                    item?.status === 'unread' && (
                                                        <span 
                                                        className='text-xs bg-green-500 text-pure px-1.5 rounded-md'
                                                        >
                                                            New
                                                        </span>
                                                    )
                                                }
                                            </div>
                                            <p className='mt-1 text-gray-500 text-sm'>
                                                {item?.description}
                                            </p>
                                            <div className='text-xs mt-1 text-primary'>
                                                <TimeAgo date={item?.createdAt} />
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default memo(Notifications)