import PopupLayout from 'components/global/PopupLayout'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowEditModel } from 'redux/reducers/blogCategoryReducer';
import { PulseLoader } from 'react-spinners';
import { Form, Formik } from 'formik';
import blogCategoryValidations from 'validations/blogCategoryValidations';
import FormControl from 'components/global/form/FormControl';
import { blogCategory_edit } from 'redux/actions/blogCategoryActions';
import { baseURL } from 'config/api';



const EditBlogCategoryModel = () => {
    const dispatch = useDispatch();
    const { updateLoading , selectedDoc } = useSelector(state => state.blogCategory);
    const initValues = {
        name : selectedDoc?.name || '',
        image : selectedDoc?.image ? baseURL + '/blogCategories/' + selectedDoc?.image : ''
    }

    const handleSubmit = (formData) => {
        const body = {...formData}
        if(body?.image === initValues?.image) {
            delete body?.image;
        }
        dispatch(blogCategory_edit(selectedDoc?._id , body));
    }

    return (
        <PopupLayout
            setShowPopup={setShowEditModel}
            redux
        >
            <center>
                <h3 className='text-xl font-semibold text-gradient'>Edit Category</h3>
            </center>
            <Formik
                initialValues={initValues}
                validationSchema={blogCategoryValidations}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {
                    (formik) => {
                        return (
                            <Form
                                className='flex flex-col gap-4 mt-8'
                            >
                                <FormControl
                                    control='input'
                                    label='Name'
                                    placeholder='Enter category name'
                                    name='name'
                                    formik={formik}
                                />
                                <FormControl
                                    control='file'
                                    label='Image'
                                    name='image'
                                    formik={formik}
                                />
                                <div className='mt-10'>
                                    <button
                                        type='submit'
                                        className='btn-primary py-2.5 px-12'
                                        disabled={updateLoading || !formik.isValid}
                                    >
                                        {
                                            updateLoading
                                            ?
                                                <PulseLoader size={10} color='#fff' />
                                            :
                                                'Submit'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </PopupLayout>
    )
}

export default EditBlogCategoryModel